<!-- <mat-sidenav-container class="togglenav-container"> -->
<!-- <mat-sidenav> -->
<div class="sub-category">
    <div class="sub-category-item">
        <div class="d-flex flex-column text-center  align-items-center">
            <div class="all-categories " (click)="oncategorySelected('All')">
            <button mat-button class="toggle-button py-0">
                <div class="gradient-menu">
                    <img src="../../../../assets/images/icon/category-menu.svg" alt="">
                </div>
            </button>
        </div>
            <small class="toggle_title">All categories</small>
        </div>
    </div>

    <div class="sub-category-item"*ngFor="let category of categories;">
        <div class="d-flex flex-column text-center align-items-center">
            <button mat-button (click)="navigateCategoryList(category);">
                <div class="gradient-menu">
                    <img decoding="async" loading="lazy" [src]="category?.category_image_url" [alt]="category?.category_name" width="600"
                        height="600" [srcset]="category?.category_image_url" sizes="(max-width: 110px) 100vw, 110px">
                </div>
            </button>
            <small class="toggle_title">{{category?.category_name}}</small>
        </div>
    </div>
</div>



<!-- </mat-sidenav> -->

<!-- 
        <mat-sidenav-content>
            <div>
            <button mat-buttonclass="toggle-button py-0">
                <div class="gradient-menu">
                <img src="../../../../assets/images/icon/category-menu.svg" alt="">
            </div>
            </button>
        </div>
        </mat-sidenav-content> -->
<!-- </mat-sidenav-container> -->