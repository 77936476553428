<div class="row">
    <div class="col-lg-12 col-12 mx-auto">
  <div class="center-content">
      <!-- <img src="assets/images/substitute_confirmation.svg" alt="Image" class="centered-image"> -->
      <div class="text-container">
        You’ve some items on the cart! By continuing, your cart items will be cleared. Are you sure want to continue?
      </div>
      <div class="button-container col-gap-20" >
        <button class="cancelbtn gradient-btn-o btn" (click) = "onNoClick()">{{ 'myprofile.myorders.label.cancel' | translate}}</button>
        <button class="yesbtn" (click) = "onYesClick()">Ok, Sure!</button>
      </div>
    </div>
  </div>
  </div>