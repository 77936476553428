<div class="row">
    <div class="col-lg-12 col-12 mx-auto">
  <div class="center-content pt-5">
      <img src="assets/images/substitute_confirmation.svg" alt="Image" class="centered-image">
      <div class="text-container">
     <b> Only instant products can be added to the cart!</b>  
        Please select items eligible for instant delivery to proceed.
      </div>
      <div class="button-container col-gap-20" >
        <!-- <button class="cancelbtn gradient-btn-o btn" (click) = "onNoClick()">{{ 'myprofile.myorders.label.cancel' | translate}}</button> -->
        <button class="yesbtn" (click) = "onYesClick()">Ok, Sure!</button>
      </div>
    </div>
  </div>
  </div>