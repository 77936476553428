<div>
    <div class="cards" (click)="openPopup()">
        <i class="fa fa-plus"></i>
        <span>{{'myprofile.mywishlist.label.newcollection' | translate}}</span>
    </div>
    <ng-container *ngFor="let item of wishListGroups; let last = last">
        <div class="d-flex justify-content-between collection-wrapper">
            <div class="column title">
              {{ item.group_name }}
              
            </div>
          <div class="column checkbox">
            <input type="checkbox" [(ngModel)]="item.checked">
          </div>
        </div>
      </ng-container>
      <div class="bottom-row">
        <div class="buttons d-flex col-gap-20">
          <button class="gradient-btn-o column btn btn-block" (click)="close();">Cancel</button>
          <button class="submitbtn btn-block column" (click)="confirmationPopup();">Done</button>
        </div>
    </div>
</div>