import { Directive, HostListener, Input } from '@angular/core';
@Directive({
  selector: '[appCharValidation]'
})
export class CharacterValidationDirective {

  @Input() appCharValidation!: string;
    regexPattern!:string;
    replaceRegex!: RegExp;

    constructor() { }

    @HostListener('keypress', ['$event'])
    onKeyPress(event: KeyboardEvent) {
        this.getRegexPattern(this.appCharValidation);
        return new RegExp(this.regexPattern).test(event.key);
    }

    @HostListener('paste', ['$event'])
    blockPaste(event: ClipboardEvent) {
        this.replace(event);
    }

    getRegexPattern(pattern: string) {
        switch (pattern) {
            case 'alphabet':
                this.regexPattern = '^[a-zA-Z]';
                this.replaceRegex = /[^a-zA-Z]/gi;
                break;
            case 'alphabetWithSpace':
                this.regexPattern = '^[a-zA-Z ]';
                this.replaceRegex = /[^a-zA-Z ]/gi;
                break;
            case 'numeric':
                this.regexPattern = '^[0-9]';
                this.replaceRegex = /[^0-9]/gi;
                break;
            case 'alphaNumeric':
                this.regexPattern = '^[a-zA-Z0-9]';
                this.replaceRegex = /[^a-zA-Z0-9]/gi;
                break;
            case 'alphaNumericWithSpace':
                this.regexPattern = '^[a-zA-Z0-9 ]';
                this.replaceRegex = /[^a-zA-Z0-9 ]/gi;
                break;
            default:
                this.regexPattern = '^[a-zA-Z]';
                this.replaceRegex = /[^a-zA-Z]/gi;
                break;
        }
    }
    
    replace(event: ClipboardEvent) {
        this.getRegexPattern(this.appCharValidation);
        event.preventDefault();
        const pasteData = event.clipboardData?.getData('text/plain').replace(this.replaceRegex, '');
        document.execCommand('insertHtml', false, pasteData)
    }

}
