import { Component, Input, OnInit } from '@angular/core';
import { CategorySliderConfig } from '../../data/slider';
import { HomeService } from '../../services/home.service';

@Component({
  selector: 'app-category-widget',
  templateUrl: './category-widget.component.html',
  styleUrls: ['./category-widget.component.scss']
})
export class CategoryWidgetComponent implements OnInit {
  @Input() categoryDetails;
  public categories;
  public CategorySliderConfig: any = CategorySliderConfig;
  productSliderDetails = {};
  sliderMidpoint: number;
  public selectedSubcategory: any;
  public productList: any = [];
  constructor(private homeService: HomeService) { }

  ngOnInit(): void {
    console.log(this.categoryDetails)
    this.productList = this.categoryDetails?.products?.records;
    this.sliderMidpoint = Math.round(this.productList?.length / 2);
  }
  setSelectedCategory(event) {
    this.selectedSubcategory = event;
    this.sliderMidpoint = Math.round(this.selectedSubcategory?.productList?.length / 2);
  }
}
