import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { ServiceConfigs } from "src/app/constant/service.config";
import { environment } from "src/environments/environment";
import { Sliders } from "../classes/home";
import { UserLoginRequest, UserLoginResponse } from "../classes/user";

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  public Sliders: any;
  public url: string;

  constructor(private http: HttpClient,
    private config: ServiceConfigs,
    private toastrService: ToastrService) { }


  // Login
  public verifyUser(apiRequest: UserLoginRequest): Observable<UserLoginResponse> {
    this.url = (environment.mock) ? this.config.GET_LOGIN_USER.mockUrl : this.config.GET_LOGIN_USER.url;
    return this.http.post<UserLoginResponse>(`${this.config.GET_LOGIN_USER.url}`, apiRequest).pipe(
      catchError((httpErrorResponse: HttpErrorResponse) => {
        const userLoginErrorResponseData: UserLoginResponse = {
          data: httpErrorResponse.error,
          is_success: false,
          message: httpErrorResponse.error.message,
          status: httpErrorResponse.error.status
        };

        return of(userLoginErrorResponseData);
      }),
    );
  }

    // Login
    public sendRecoveryLink(apiRequest: any): Observable<UserLoginResponse> {
      this.url = this.config.SEND_RECOVERYLINK.url;
      return this.http.post<UserLoginResponse>(`${this.url}`, apiRequest).pipe(
        catchError((httpErrorResponse: HttpErrorResponse) => {
          const userLoginErrorResponseData: UserLoginResponse = {
            data: httpErrorResponse.error,
            is_success: false,
            message: httpErrorResponse.error.message,
            status: httpErrorResponse.error.status
          };
  
          return of(userLoginErrorResponseData);
        }),
      );
    }
    public resetPassword(apiRequest: any): Observable<UserLoginResponse> {
      this.url = this.config.RESET_PASSWORD.url;
      return this.http.post<UserLoginResponse>(`${this.url}`, apiRequest)
    }
  // Login
  public logout(): Observable<any> {

    return this.http.get<UserLoginResponse>(`${this.config.LOGOUT_USER.url}`).pipe(
      catchError((httpErrorResponse: HttpErrorResponse) => {
        const userLoginErrorResponseData: UserLoginResponse = {
          data: httpErrorResponse.error,
          is_success: false,
          message: httpErrorResponse.error.message,
          status: httpErrorResponse.error.status
        };

        return of(userLoginErrorResponseData);
      }),
    );
  }

  // SignUp
  public signUpUser(apiRequest: any): Observable<UserLoginResponse> {
    //this.url=(environment.mock)? this.config.POST_SIGNUP_USER.mockUrl: this.config.POST_SIGNUP_USER.url;
    return this.http.post<UserLoginResponse>(`${this.config.POST_SIGNUP_USER.url}`, apiRequest).pipe(
      catchError((httpErrorResponse: HttpErrorResponse) => {
        const userSignUpErrorResponseData: UserLoginResponse = {
          data: httpErrorResponse.error,
          is_success: false,
          message: httpErrorResponse.error.message,
          status: httpErrorResponse.error.status
        };

        return of(userSignUpErrorResponseData);
      }),
    );
  }
}
