import { Component, Input, OnInit } from '@angular/core';
import { Product } from '../../classes/product';
import { Router } from '@angular/router';


@Component({
  selector: 'app-multi-item-slider',
  templateUrl: './multi-item-slider.component.html',
  styleUrls: ['./multi-item-slider.component.scss']
})
export class MultiItemSliderComponent implements OnInit {

  @Input() products: Product[];
  public productCollections: any[] = [];
  @Input() ProductSliderConfig: any;
  @Input() sliderDetails: object;
  @Input() targetUrl: string;
  @Input() productId: string;
  public dynClass = {prod1: 'col-3', prod2: 'col-6', prod3: 'col-9'};
  constructor(private router: Router) { }

  ngOnInit(): void {

  }
  addNewRoute(){
    let navigationExtras = {
      queryParams: { 'productId': this.productId }
    };
    this.router.navigate([this.targetUrl], navigationExtras);
  }

}
