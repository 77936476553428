import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { CUSTOMER_PORTAL_CONSTANT } from '../constant/constant';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss']
})
export class PagesComponent implements OnInit {

  public url : any; 

  constructor(private router: Router) {  
    this.router.events.subscribe((event) => {
          if (event instanceof NavigationEnd) {
            this.url = event.url;
          }
    });
  }

  ngOnInit(): void {
  }

  public checkIfLogin() {
    return JSON.parse(localStorage.getItem(CUSTOMER_PORTAL_CONSTANT.CURRENT_USER))?.customer_id != undefined ? true : false;
  }

}
