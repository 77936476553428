import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CUSTOMER_PORTAL_CONSTANT } from 'src/app/constant/constant';

@Component({
  selector: 'app-category-sub-menu',
  templateUrl: './category-sub-menu.component.html',
  styleUrls: ['./category-sub-menu.component.scss']
})
export class CategorySubMenuComponent implements OnInit {
@Input() menuDetails;
@Input() currentTab;
@Output() setSubcategory  : EventEmitter<any> = new EventEmitter<any>();
public categoryBackgroundImage: string;
  constructor(private router: Router) { 
    this.categoryBackgroundImage = CUSTOMER_PORTAL_CONSTANT.DEFAULT_CATEGORY_BANNER;
  }

  ngOnInit(): void {
    console.log('menuDetails  ===', this.menuDetails);
  }
onSubcategorySelected(selectedCategory){
  this.setSubcategory.emit(selectedCategory)
}

navigateCategoryList(category: any) {  
  this.router.navigate(["/shop/category-product-list", category?.category_id]);
}

navigateToCategory() {
  const categoryId = this.menuDetails.category_id;
  this.router.navigate(['/shop/category-product-list', categoryId]);
}

}
