<!-- <div id="external-container"></div>
<html>
<head>
    
</head>
<body>
     -->
<ngx-spinner type="ball-scale-multiple"></ngx-spinner>
  
    <!-- <p>We are glad to have you, name as a member.</p>
    <div id="embed-target"> </div>
    <input type="button" value="Pay with Embedded Page" onclick="Checkout.showEmbeddedPage('#embed-target');" />
    <input type="button" value="Pay with Payment Page" onclick="Checkout.showPaymentPage();" />
 
</body>
</html> -->
