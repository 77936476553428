import { Component, ElementRef, HostListener, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { CUSTOMER_PORTAL_CONSTANT } from 'src/app/constant/constant';
import { AddressConfirmationDialogueComponent } from 'src/app/pages/address-confirmation-dialogue/address-confirmation-dialogue.component';
import { ConfirmationDialogueComponent } from 'src/app/pages/confrimation-dialogue/confrimation-dialogue.component';
import { DeliveryTypeConfirmationComponent } from 'src/app/pages/delivery-type-confirmation/delivery-type-confirmation.component';
import { DeliveryTypeDialogComponent } from 'src/app/pages/delivery-type-dialog/delivery-type-dialog.component';
import { DialogPopupComponent } from 'src/app/pages/dialog-popup/dialog-popup.component';
import { AddressList } from '../../classes/address';
import { ProductSearchResult } from '../../classes/product';
import { AppSettings } from '../../classes/user';
import { MenuContainerComponent } from '../../components/menu-container/menu-container.component';
import { AddressService } from '../../services/address.service';
import { CartService } from '../../services/cart.service';
import { CustomerService } from '../../services/customer.service';
import { GlobalService } from '../../services/global.service';
import { HomeService } from '../../services/home.service';
import { MyProfileService } from '../../services/myprofile.service';
import { NotificationsService } from '../../services/notifications.service';
import { ProductService } from '../../services/product.service';
import { CommonUtil } from '../../util/common-util';
import { DiscountPopupComponent } from 'src/app/shop/discount-popup/discount-popup.component';
import { OrderService } from '../../services/order.service';

@Component({
  selector: 'app-header-five',
  templateUrl: './header-five.component.html',
  styleUrls: ['./header-five.component.scss']
})
export class HeaderFiveComponent implements OnInit {
  public cartCount: any = 0;
  public notificationCount : number = 0;
  public stick: boolean = false;
  public layout: string;
  public isKeyFrame: boolean = false;
  public storeId: number;
  keyword = 'name';
  @ViewChild('auto') auto;
  public products: any;
  displayedColumns: string[] = ['image', 'items'];
  dataSource = [];
  public cartList: any;
  deliveryType: string = 'Instant'
  showPopup = false;
  public urlList: any = ['/home/terms&conditions', '/home/privacypolicy', '/home/return&cancellationpolicy', '/shop/aboutus'];
disableDeliveryUpdate : boolean = false;
  selectEvent(item) {
    console.log('item ===', item);
    this.globalService.setSearchCategoryState(item.name);
    if(item.is_product){
    this.router.navigate(["/shop/product-view", item.id]);
    }  else if(item.is_category){
      this.router.navigate(["/shop/category-product-list", item.id]);

    }
    this.auto.close();
    this.auto.clear();
    // do something with selected item
  }

  onChangeSearch(search: string) {
    this.productService.searchByProductAndCategoty(CUSTOMER_PORTAL_CONSTANT.DEFAULT_PAGE_INDEX, CUSTOMER_PORTAL_CONSTANT.DEFAULT_PAGE_SIZE, search, false).subscribe((result) => {

      let data: any = result?.data
      this.products = data;
      console.log(this.products)
    })
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }
  search() {
    console.log(this.auto.query)
    if (this.auto?.query?.length > 2) {
      this.productService.searchProductList = this.products;
      this.router.navigate(['/shop/search-product-list', this.auto.query]);
      this.auto.close();
      this.auto.clear();
    }
  }
  onFocused(e) {
    // do something
  }
  @ViewChild('toggleButton') toggleButton: ElementRef;
  @ViewChild('menu') menu: ElementRef;
  isMenuOpen: boolean = false;
  selectedAddress: any = {};
  authUser: boolean = false;
  addressList: any = [];
  addressOptions: any = [];
  loggedInUser: any;
  constructor(private router: Router, private renderer: Renderer2, private cartService: CartService,
    public globalService: GlobalService, private toastrService: ToastrService, private notificationsService : NotificationsService,
    private productService: ProductService, private translateService: TranslateService,
    private customerService: CustomerService, private addressService: AddressService,private homeService : HomeService,
    private dialog: MatDialog, private myProfileService: MyProfileService, private orderService: OrderService) {
    this.globalService.getMyAddressSectionDefaultAddressState().subscribe((data: string) => {
      if (data && data === 'DefaultAddressUpdated') {
        this.getAddressList();
      }
    });
    if (localStorage.getItem(CUSTOMER_PORTAL_CONSTANT.CURRENT_USER) !== null && localStorage.getItem(CUSTOMER_PORTAL_CONSTANT.CURRENT_USER) && CommonUtil.getStoreIdFromLocalStorageUserProfile('customer_id')) {

    this.getAddressList();      
    this.getCustomerDeliveryType();  
      }
    let ls: AppSettings = JSON.parse(localStorage.getItem('appSettings'));
    this.layout = (ls !== null && ls.layout === 'ltr') ? 'rtl' : 'ltr';
    this.renderer.listen('window', 'click', (e: Event) => {
      if (e.target !== this.toggleButton?.nativeElement && e.target !== this.menu?.nativeElement && this.isMenuOpen) {

        this.isMenuOpen = false;
      }
    });

    this.globalService.getShowAddressState().subscribe((data: string) => {
      if (data?.length > 1) {
        setTimeout(() => {
          window.location.assign('/');
        }, 0);
      }
    });
    this.globalService.getCartCountState().subscribe((data: string) => {
      if (data && data === 'getCartCount') {
        this.getCartCount();
      }
    });
    // this.globalService.getHeaderSectionDefaultAddressState().subscribe((data: string) => {
    //   if (data && data === 'DefaultAddressUpdated') {
    //     this.getAddressList();
    //   }
    // });
    const isGuestUser = !(JSON.parse(localStorage.getItem('currentUser'))?.customer_id);
    if(isGuestUser) {
      homeService.customerStoreId.subscribe((data) => {
        if(data) {
          this.initializeStore();
          this.getCartCount();
        }
      })
    } else {
      this.initializeStore();
      this.getCartCount();
    }
    
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
    if(event.url == '/shop/checkout'){
      this.disableDeliveryUpdate = true;
    } else {
      this.disableDeliveryUpdate = false;
    }
      }
});
  }
  // addressOptions: Array<object> = [
  //   {
  //     addressType: 'Guest house',
  //     address: 'Orchid resort, thambaram, chennai'
  //   },
  //   {
  //     addressType: 'Office',
  //     address: 'DAC promoters, thambaram, chennai'
  //   },
  //   {
  //     addressType: 'Home',
  //     address: 'Demonte avenue, Besant nagar, chennai'
  //   }
  // ]
  ngOnInit(): void {
    
    this.isKeyFrame = window.location !== window.parent.location;
    this.router.events.subscribe((urlParams: any) => {
      if(this.urlList.includes(urlParams.url))
          this.isKeyFrame = true;
    });
    if (localStorage.getItem(CUSTOMER_PORTAL_CONSTANT.CURRENT_USER) !== null && localStorage.getItem(CUSTOMER_PORTAL_CONSTANT.CURRENT_USER) && CommonUtil.getStoreIdFromLocalStorageUserProfile('customer_id')) {
      console.log(localStorage.getItem(CUSTOMER_PORTAL_CONSTANT.CURRENT_USER))      
        this.myProfileService.getPersonalDetails().subscribe((response:any)=> {
          if(response?.is_success && response?.data) {
            const data = response?.data;
            this.loggedInUser = data?.first_name;
          } else {
            console.error('Error fetching personal details:', response?.message);
          }
        },
        (error) => {
          console.error('Error fetching personal details:', error);
        }
        );
      this.authUser = true;
      this.cartService.getCartList(CUSTOMER_PORTAL_CONSTANT.SHOPPING_CART).subscribe((cartList: any) => {
        console.log('getCartList ====', cartList);
        let response: any = cartList?.data;
        let responseData: any = response?.items;
        this.cartList = responseData;
        this.dataSource = responseData;
      });
      // this.getNotificationCount();
      this.globalService.getNotificationCountState().subscribe((data: any) => this.getNotificationCount());
    } else {
      this.authUser = false
    }
  }
  // @HostListener("window:scroll", [])
  toggleMenu() {
    setTimeout(() => {
      this.isMenuOpen = !this.isMenuOpen;
    }, 0);
  }
  onWindowScroll() {
    let number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (number >= 150 && window.innerWidth > 400) {
      this.stick = true;
    } else {
      this.stick = false;
    }
  }

  initializeStore() {
    this.storeId = CommonUtil.getStoreIdFromLocalStorageUserProfile('store_id'),
      this.homeService.getcategories(undefined, undefined, 31).subscribe((result) => {
        if (result?.data) {
          let response: any = result?.data;
          let megaMenu = { 'megaMenu': response };
          localStorage.setItem('megaMenu', JSON.stringify(megaMenu));
          this.globalService.setMegamenuDetails(megaMenu);
        }
      })
  }

  getNotificationCount() {
     this.notificationsService.getNotificationsList().subscribe((notificationList: any) => {
      this.notificationCount = (notificationList.data.records.filter((data: any) => data.notification_status != 'Read')).length;
      // this.notificationList = (localStorage.getItem('isnorecord') === 'true') ? [] : notificationList;
    });
    setInterval(() => {
      this.notificationsService.getNotificationsList().subscribe((notificationList: any) => {
        this.notificationCount = (notificationList.data.records.filter((data: any) => data.notification_status != 'Read')).length;
        // this.notificationList = (localStorage.getItem('isnorecord') === 'true') ? [] : notificationList;
      });
    }, 120000);
  }
  customizeLayoutType(val) {
    let appSettings: AppSettings = {};
    appSettings.layout = val;
    appSettings.language = (val === 'ltr') ? 'ar' : 'en';
    if (val == 'ltr') {
      document.body.classList.remove('ltr')
      document.body.classList.add('rtl')
      this.layout = 'rtl';
      this.globalService.translate('ar');
    } else {
      document.body.classList.remove('rtl');
      document.body.classList.add('ltr');
      this.layout = 'ltr';
      this.globalService.translate('en');
    }
    localStorage.setItem('appSettings', JSON.stringify(appSettings));
    window.location.reload();
  }
  setAddress(address) {
    this.globalService.setHeaderSectionDefaultAddressState('DefaultAddressUpdated');
    this.selectedAddress = address;//`${address.address1} ${address.address2}`
    this.globalService.customerDeliveryAddress = address;    
   // this.toggleMenu();
  }
  public validateUser(link): void {
    if (this.authUser) {
      if(!this.globalService.customerDeliveryAddress && link.includes('/cart-view')) {
        this.toastrService.error('Add delivery address', 'Error');
        this.router.navigateByUrl('/pages/myaddress');
      }
      else
        this.router.navigateByUrl(link);
    }
    else {
      if (link != '/pages/edit-profile') {
        this.toastrService.error('Login to continue', 'Error');
      }
      this.router.navigateByUrl('/public/login');
    }
  }

  public navigatetoAddNewAddress(): void {
    this.globalService.setAddNewAddressState('addNew');
  }
  onCleared(e) {
    // e.stopPropagation();
    this.auto.close();
    this.auto.clear();
  }
  increment(product, qty = 1) {
    product.item_quantity++;
    this.productService.updateCartQuantity(product, qty);
  }

  // Decrement
  decrement(product, qty = -1) {
    if (product.item_quantity > 0) {
      product.item_quantity--;
      this.productService.updateCartQuantity(product, qty);
    }
  }

  public removeItem(product: any) {
    this.productService.removeCartItem(product);
  }
  updateDelivery() {
    if(!(localStorage.getItem(CUSTOMER_PORTAL_CONSTANT.CURRENT_USER) !== null && localStorage.getItem(CUSTOMER_PORTAL_CONSTANT.CURRENT_USER) && CommonUtil.getStoreIdFromLocalStorageUserProfile('customer_id'))) {
    
      this.toastrService.error('Login to continue', 'Error');
      return
    }
    if(this.disableDeliveryUpdate){
      return
    }
    let fallBackDeliveryType: string = this.deliveryType;
    console.log('fallBackDeliveryType ===', fallBackDeliveryType);
    if(this.cartCount > 0 && this.deliveryType != 'Instant'){
      this.askForConfirmation(this.deliveryType);
    } else {
      this.toggleDeliveryType();
      this.updateDeliveryType(this.deliveryType);
    }
  }

  toggleDeliveryType() {
    if (this.deliveryType === CUSTOMER_PORTAL_CONSTANT.INSTANT_DELIVERY_TYPE) {
      this.deliveryType = CUSTOMER_PORTAL_CONSTANT.SCHEDULED_DELIVERY_TYPE;
    } else {
      this.deliveryType = CUSTOMER_PORTAL_CONSTANT.INSTANT_DELIVERY_TYPE;
    }
  }

  getCartCount(): void {
    this.productService.getCartCount().subscribe((result) => {
      console.log('ABCD -----------', result);
      this.cartCount = result?.data;
    })
  }

  getCustomerDeliveryType() {
    this.customerService.getCustomerDeliveryType().subscribe((result: any) => {
      console.log('result ===', result);
      let response: any = result?.data;
      this.deliveryType = (response?.selected_order_type) ? response?.selected_order_type : CUSTOMER_PORTAL_CONSTANT.INSTANT_DELIVERY_TYPE;
      console.log('this.deliveryType ---------------------', this.deliveryType);
      localStorage.setItem('CustomerDeliveryType', this.deliveryType);
if(!response?.selected_order_type){
      this.dialog.open(DeliveryTypeDialogComponent, {
        width: '687px',
        height: '290px',
        maxWidth:'95vw',
      }).afterClosed().subscribe((data)=> {
        console.log(data)
        this.openDiscountPopup();
        if(data){
        //  this.askForConfirmation(data)
         if(this.cartCount > 0){
          this.askForConfirmation(data);
            } else {
              this.updateDeliveryType(data)
            }
        }
      })
    } else {
        this.openDiscountPopup();
    }
    })
  
  }
askForConfirmation(delivery_type){
  this.dialog.open(DeliveryTypeConfirmationComponent,{
    width: '687px',
    height: '290px',
    maxWidth:'95vw',
    
  }).afterClosed().subscribe((data) => {
    console.log(data)
    if(data){
      this.toggleDeliveryType();
      this.updateDeliveryType(this.deliveryType);
      localStorage.removeItem('checkout');
    }
  })
}
openDiscountPopup() {
  if (!localStorage.getItem('isDiscount')) {
    this.orderService.getMyOrders().subscribe((response: any) => {
      if (response.data.records.length == 0) {
        localStorage.setItem('isDiscount', '1')
        this.dialog.open(DiscountPopupComponent, {
          width: '571px',
          height: '250px',
          data: {
            from: 'header',
            title: '',
            subTitle: 'Welcome to our Grand Opening! Place your first order with us and enjoy a 20% discount! Offer valid for your first order, with a maximum discount of 40 QR. The voucher will be automatically applied on eligible orders. Hurry, this offer is available for the first 5,000 customers. Terms and conditions apply'
          }
        });
      }
    });
  }
}

updateDeliveryType(delivery_type){
  let requestParams: any = {
    'delivery_type': delivery_type,
    'customer_id': CommonUtil.getStoreIdFromLocalStorageUserProfile('customer_id'),
    'store_id':CommonUtil.getStoreIdFromLocalStorageUserProfile('store_id')
  };
  this.customerService.updateDeliveryType(requestParams).subscribe((result: any) => {
    console.log('resultresult  ===', result);
    let response: any = result?.data;
    //Fallback implementation
    if(response?.is_updated_successfully){
      this.toastrService.success(this.translateService.instant('common.label.updatedsuccessfully'), this.translateService.instant('myprofile.mywishlist.label.success!'));
    this.deliveryType = delivery_type;      
    localStorage.setItem('CustomerDeliveryType', delivery_type);
    CommonUtil.updateStoreID(response?.store_id);
    window.location.reload();
    }
  });
}
  setAsDefaultAddress(index: number, address: AddressList): void {
    //this.toggleMenu();
    let AddressParams: any = {address_id:'', customer_id:'',is_customer_agreed:''};
    AddressParams.address_id = address?.address_id;
    AddressParams.customer_id = CommonUtil.getStoreIdFromLocalStorageUserProfile('customer_id');
    AddressParams.is_customer_agreed = true;
    let isDifferentStoreId: boolean;
    isDifferentStoreId = this.validateStoreId(address);
    console.log('isDifferentStoreId   ===', isDifferentStoreId);

    if(isDifferentStoreId) {
      const dialogRef = this.dialog.open(AddressConfirmationDialogueComponent, {
        width: '571px',
        height: '207px',
        data: {
          title: 'Confirmation',
          message: 'myprofile.myaddress.label.changeDeliveryAddress',
          name: 'myprofile.myaddress.label.currentCartMayLost'
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result) {
          this.addressService.setDefaultAddress(AddressParams).subscribe((response: any) => {
            console.log('response ===', response);
            if(response?.is_success) {
              this.toastrService.success(this.translateService.instant('myprofile.myaddress.label.toastersetdefaultsuccess'), this.translateService.instant('myprofile.mywishlist.label.success!'));
              //this.setDefaultButton(index, address);
              //this.getAddressList();
              CommonUtil.updateStoreID(address?.area_store_id);
              let customerDeliveryType = localStorage.getItem('CustomerDeliveryType');
              if(customerDeliveryType == 'Scheduled') {
                setTimeout(() => {  // Toaster display purpose time delay 1 sec
                  clearTimeout();
                  window.location.reload();
                }, 1000);
              } else {
                this.updateDeliveryType('Scheduled');
              }
            }
          });
        }    
      });
    } else {
      this.addressService.setDefaultAddress(AddressParams).subscribe((response: any) => {
        console.log('response ===', response);
        if (response?.is_success) {
          this.getAddressList();
          this.toastrService.success(this.translateService.instant('myprofile.myaddress.label.toastersetdefaultsuccess'), this.translateService.instant('myprofile.mywishlist.label.success!'));
        }
      });
    }
  }

  validateStoreId(address: AddressList): boolean {
    if(address?.area_store_id === CommonUtil.getStoreIdFromLocalStorageUserProfile('store_id')) {
      return false;
    }
    return true;
  }

  getAddressList() {
    this.addressService.getMyAddressList().subscribe((addressList: any) => {
      let response: any = addressList?.data;
      let responseData: any = response?.records;
      this.addressList = responseData || [];
      console.log('this.addressList ====', this.addressList);
      this.addressOptions = []; // Reset Array
      this.selectedAddress = {}; // Reset Default
      this.addressList.forEach(element => {
        if(element.is_default_address && Object.keys(this.selectedAddress).length === 0) {   
          console.log('YEH AM COMING HERE ----------------------')       
          this.selectedAddress = element;//`${element.address1} ${element.address2}`
          this.setAddress(element);
          this.globalService.customerDeliveryAddress = element;
        } else {
          this.addressOptions.push(element);
        }          
      });
      console.log('this.addressOptions ------------', this.addressOptions);
    });
  }

  openPopup() {
    this.showPopup = true;
    const dialogRef = this.dialog.open(MenuContainerComponent, {
      width: '80vw',
      height: '75vh',
      panelClass:'mega-menu-container'
     
    });
  }

  closePopup() {
    this.showPopup = false;
  }
}
