import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ServiceConfigs } from 'src/app/constant/service.config';
import { environment } from 'src/environments/environment';
import { AddressList } from '../classes/address';
import { MyOrders } from '../classes/order';
import { Policies } from '../classes/policies';


@Injectable({
  providedIn: 'root'
})
export class PoliciesService {
  public url: string;
public policyList  = [];
  constructor(private router: Router, private http: HttpClient,
    private config: ServiceConfigs) { }

  
// My Address list 
public getPoliciesList(): Observable<Policies[]> {
 let  appSettings = JSON.parse(localStorage.getItem('appSettings'));
 let  isArabic = appSettings.language == 'ar' ? true : false;

  this.url= isArabic ? this.config.GET_POLICIES_LIST_AR.mockUrl : this.config.GET_POLICIES_LIST_EN.mockUrl;
  return this.http.get<Policies>(`${this.url}`).pipe(
      catchError((httpErrorResponse: HttpErrorResponse) => of(httpErrorResponse.error))
  );
}
getApiData(name): Observable<any> {

 let  appSettings = JSON.parse(localStorage.getItem('appSettings'));
 let  isArabic = appSettings.language == 'ar' ? true : false;
  this.url= this.config.GET_POLICIES_LIST_EN.url;
  let queryParams = new HttpParams();
  queryParams = queryParams.append("name", name);
  queryParams = queryParams.append("language_culture", isArabic ? 'ar-QA' : 'en-US');
  return this.http.get(this.url,{params : queryParams});
}



getAllPolicyData(): Observable<any[]> {
  return forkJoin([
    this.getApiData('Terms & Conditions'),
    this.getApiData('Privacy policy'),
    this.getApiData('Return & cancellation policy'),
    this.getApiData('Cookies policy')
  ]).pipe(tap((policyList) =>  { policyList.forEach(policy => {
    if(policy.is_success){
      this.policyList.push(policy.data.Policy)
      console.log(this.policyList)
    }
  })}));
}
}
