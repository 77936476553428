import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ServiceConfigs } from 'src/app/constant/service.config';
import { environment } from 'src/environments/environment';
import { UserLoginRequest, UserLoginResponse, UserLoginResponseData } from '../classes/user';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public accessToken: string;
  private currentUserSubject: BehaviorSubject<UserLoginResponseData>;
  public currentUser: Observable<UserLoginResponseData>;

  constructor(private httpClient: HttpClient,private config : ServiceConfigs) {
    this.currentUserSubject = new BehaviorSubject<UserLoginResponseData>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
    const oauth: any = JSON.parse(localStorage.getItem('currentUser'));
    if (oauth && oauth.access_token) {
      this.accessToken = oauth.access_token;
    }
  }

  public getAccessToken() {
    return this.accessToken;
  }

  public setAccessToken(token) {
    this.accessToken = token.access_token;
  }

  public get currentUserValue(): UserLoginResponseData {
    return this.currentUserSubject.value;
  }

  public login(apiRequest: UserLoginRequest): Observable<UserLoginRequest> {
return this.httpClient.post<UserLoginRequest>(`${this.config.GET_LOGIN_USER.url}`, apiRequest).pipe(map(user => {
  // store user details and jwt token in local storage to keep user logged in between page refreshes
  localStorage.setItem('currentUser', JSON.stringify(user));
  this.currentUserSubject.next(user);
  return user;
})
);
}

}

