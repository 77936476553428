import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CUSTOMER_PORTAL_CONSTANT } from 'src/app/constant/constant';
import { Product } from 'src/app/shared/classes/product';
import { ProductService } from 'src/app/shared/services/product.service';
import { CartModalComponent } from '../../modal/cart-modal/cart-modal.component';
import { QuickViewComponent } from '../../modal/quick-view/quick-view.component';
import { GlobalService } from 'src/app/shared/services/global.service';
import { CommonUtil } from 'src/app/shared/util/common-util';
import { AddToCart } from 'src/app/shared/classes/order';
import { OrderService } from 'src/app/shared/services/order.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-product-card-tab',
  templateUrl: './product-card-tab.component.html',
  styleUrls: ['./product-card-tab.component.scss']
})
export class ProductCardTabComponent implements OnInit {

  @Input() product: Product;
  @Input() brandLogo: string;
  @Input() currency: any = this.productService.Currency; // Default Currency
  @Input() cartModal: boolean = false; // Default False
  
  @ViewChild("quickView") QuickView: QuickViewComponent;
  @ViewChild("cartModal") CartModal: CartModalComponent;


  public storeId: number = 0;

  constructor(private productService: ProductService,private router : Router,
    private toastrService : ToastrService, private globalService: GlobalService,
    private orderService: OrderService, private translateService: TranslateService) { }

  ngOnInit(): void {
    this.storeId = CommonUtil.getStoreIdFromLocalStorageUserProfile('store_id');
    if(this.product.unit_of_measure == 'KG' || this.product.unit_of_measure == 'GR') {
      this.product.offer_price = Number((this.product.offer_price * 1000).toFixed(2));
      this.product.was_price = Number((this.product.was_price * 1000).toFixed(2));
    }
  }

  // Get Product Color
  Color(variants) {
    const uniqColor = []
    for (let i = 0; i < Object.keys(variants).length; i++) {
      if (uniqColor.indexOf(variants[i].color) === -1 && variants[i].color) {
        uniqColor.push(variants[i].color)
      }
    }
    return uniqColor
  }

  // Change Variants


  addToCart(product: any) {
    this.productService.addToCart(product);
  }
  addToCartList(product: any, requestType: string) {
    if (!(JSON.parse(localStorage.getItem(CUSTOMER_PORTAL_CONSTANT.CURRENT_USER))?.customer_id)){
      this.toastrService.error('Login to continue','Error');
      this.router.navigateByUrl('/public/login');
      return
    } 
    if(!this.globalService.customerDeliveryAddress) {
      this.toastrService.error('Add delivery address', 'Error');
      this.router.navigateByUrl('/pages/myaddress');
      return;
    }
    let deliveryType = localStorage.getItem('CustomerDeliveryType');
    let is_instant = product?.is_instant
    if( deliveryType == 'Instant' && !is_instant){
      this.toastrService.error('Instant delivery not availble for this product', 'Error');
      // this.router.navigateByUrl('/public/login');
      return
    }
    // ADD WISHLIST & ADDCART same functionality here
    this.productService.addToCartItem(product, product.cart_item_quantity);
        //this.isFavourite = !this.isFavourite;
  }
  addToWishlist(product: any) {
    this.productService.addToWishlist(product);
  }

  addToCompare(product: any) {
    this.productService.addToCompare(product);
  }
  getValue(offerPrice: any, type: string): any {
    offerPrice = offerPrice != 0 ? offerPrice : '0.00'
    if(offerPrice) {
      let parts = offerPrice.toString().split('.');
        if(type === 'Int') {
          return parts[0] | 0;
        } else {
          return (parts[1]) ? parts[1].length > 1 ? parts[1] : parts[1]+'0' : '00';     
        }
    }
  }

  quatityUpdate(product: any, type: string) {
    if (!(JSON.parse(localStorage.getItem(CUSTOMER_PORTAL_CONSTANT.CURRENT_USER))?.customer_id)) {
      this.toastrService.error('Login to continue', 'Error');
      this.router.navigateByUrl('/public/login');
      return
    }
    let isKg = product?.unit_of_measure == 'KG' || product?.unit_of_measure == 'GR';
    let maxQuantity = isKg ? Number(product.actual_maximum_quantity) * 1000 : product?.maximum_quantity;
    let minQty = isKg ? product.minimum_quantity * 1000 : product.minimum_quantity;
    if (type === 'increment' && product?.cart_item_quantity < maxQuantity) {
      isKg ? product.cart_item_quantity += minQty : product.cart_item_quantity++ ;
      this.addToCartList(product, '')
    } else if (type === 'decrement' && product?.cart_item_quantity > 1 && product?.cart_item_quantity > minQty) {
      isKg ? product.cart_item_quantity -= minQty : product.cart_item_quantity--;
      this.addToCartList(product, '')
    } else if (type === 'decrement' && (product?.cart_item_quantity === 1 || product?.cart_item_quantity == minQty) ) {
      isKg ? product.cart_item_quantity -= minQty : product.cart_item_quantity--;
      this.deleteCart(product);

    }
  }

  // Decrement
  deleteCart(product) {
    let addToCart: AddToCart = {};
    addToCart = CommonUtil.prepareAddCart(product, product.cart_item_quantity);
    addToCart.shopping_cart_type = CUSTOMER_PORTAL_CONSTANT.SHOPPING_CART;
    this.orderService.removeCartItem(addToCart).subscribe((removeListResponse: any) => {
      if (removeListResponse.is_success) {
        this.globalService.setCartCountState('getCartCount');
        this.toastrService.success(this.translateService.instant('myprofile.mywishlist.label.toasterremovedcart'), this.translateService.instant('myprofile.mywishlist.label.success!'));
      }

    });
  }

  convertQty(rowData: any, type: string = 'unit') {
    let cnvrtQty: any = 0;
    if(rowData.unit_of_measure == 'KG' || rowData.unit_of_measure == 'GR') {
      cnvrtQty = rowData.cart_item_quantity;
      if(type == 'unit')
        cnvrtQty = cnvrtQty >= 1000 ? cnvrtQty / 1000 + 'Kg' : cnvrtQty + 'g';
      else
        cnvrtQty = cnvrtQty >= 1000 ? cnvrtQty / 1000 : cnvrtQty;
    } else {  
      cnvrtQty = rowData.cart_item_quantity;
    }
    return cnvrtQty;
  }
  
}
