export const CUSTOMER_PORTAL_CONSTANT: AppConstant = {
    DEFAULT_LANGUAGE: 'en',
    ARABIC_LANGUAGE: 'ar',
    CURRENT_USER: 'currentUser',
    CUSTOMER_ID: 'customer_id',
    STORE_ID: 'store_id',
    SHOPPING_CART: 'ShoppingCart',
    WISH_LIST: 'Wishlist',
    USER_PROFILE: 'UserProfile',
    DEFAULT_PAGE_INDEX: 1,
    DEFAULT_PAGE_SIZE: 30,
    DEFAULT_CATEGORY_BANNER: 'assets/images/categories/category-widget1.jpg',
    CATEGORIES_LIST_DEFAULT_PAGE_SIZE: 20,
    DEFAULT_SORTING: 'Position',
    INSTANT_DELIVERY_TYPE: 'Instant',
    SCHEDULED_DELIVERY_TYPE: 'Scheduled',
    DEFAULT_CATEGORY_BANNER_SINGLE_PORTRAIT: '/assets/images/placeholder/SinglePortrait.jpg',
    DEFAULT_CATEGORY_BANNER_DUAL_SQUARE: '/assets/images/placeholder/DualSquare.jpg',
    DEFAULT_SUB_CATEGORY_BANNER_ODD : '/assets/images/placeholder/SubMenu_Fresh_Home_Static.png',
    DEFAULT_SUB_CATEGORY_BANNER_EVEN : '/assets/images/placeholder/SubMenu_Grocery_Home_Static.png',
    ADDCART_ERROR  : 'Please verify the product contains proper tier price or proper offer date.',
    ADDCART_ERROR_MESSAGE : 'Megamart restricted this product.',
    TOPCATEGORIES_BANNER_TARGET_SIZE: 250
}

interface AppConstant {
    DEFAULT_LANGUAGE: string;
    ARABIC_LANGUAGE: string;
    CURRENT_USER: string;
    CUSTOMER_ID: string;
    STORE_ID: string;
    SHOPPING_CART: string;
    WISH_LIST: string;
    USER_PROFILE: any;
    DEFAULT_PAGE_INDEX: number;
    DEFAULT_PAGE_SIZE: number;
    CATEGORIES_LIST_DEFAULT_PAGE_SIZE: number;
    DEFAULT_CATEGORY_BANNER: string;
    DEFAULT_SORTING: string;
    INSTANT_DELIVERY_TYPE: string;
    SCHEDULED_DELIVERY_TYPE: string;
    DEFAULT_CATEGORY_BANNER_SINGLE_PORTRAIT: string;
    DEFAULT_CATEGORY_BANNER_DUAL_SQUARE: string;
    DEFAULT_SUB_CATEGORY_BANNER_ODD : string;
    DEFAULT_SUB_CATEGORY_BANNER_EVEN : string;
    ADDCART_ERROR: string;
    ADDCART_ERROR_MESSAGE: string;
    TOPCATEGORIES_BANNER_TARGET_SIZE: number;

}