<div class="count_down">
  <p id="demo" class="d-flex space">
      <span class="time">
          <span class="timer-num">{{ getTensHours() }}</span>
     
          <span class="timer-num">{{ getOnesHours() }}</span>
      </span>
      <span class="padding-l">:</span>
      <span class="time">
        <span class="timer-num">{{ getTensMinutes() }}</span>

          <span class="timer-num">{{ getOnesMinutes() }}</span>
      </span>
      <span class="padding-l">:</span>
      <span class="time">
        <span class="timer-num">{{ getTensSeconds() }}</span>
   
      <span class="timer-num">{{ getOnesSeconds() }}</span>
  </span>
  </p>
</div>
