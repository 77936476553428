import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nested-megamenu',
  templateUrl: './nested-megamenu.component.html',
  styleUrls: ['./nested-megamenu.component.scss']
})
export class NestedMegamenuComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
