<div class="payment row">
         <form  name="redirectForm">
            <div class="d-flex">
               <label class="d-flex fw-bold align-items-center">Action:</label>
               <input type="text" name="Action" [(ngModel)]="paymentForm.Action"/><br/>
            </div>
            <div class="d-flex">
               <label class="d-flex fw-bold align-items-center">Amount:</label>
               <input type="text" name="Amount"  [(ngModel)]="paymentForm.Amount"/> <br/>
            </div>
            <div class="d-flex">
               <label class="d-flex fw-bold align-items-center">Bank Id:</label>
               <input type="text" name="BankID" [(ngModel)]="paymentForm.BankID"/><br/>
            </div>
            <div class="d-flex">
               <label class="d-flex fw-bold align-items-center">Currency:</label>
               <input type="text" name="CurrencyCode" [(ngModel)]="paymentForm.CurrencyCode"/><br/>
            </div>
            <div class="d-flex">
               <label class="d-flex fw-bold align-items-center">Extra Fields:</label>
               <input type="text" name="ExtraFields_f14" [(ngModel)]="paymentForm.ExtraFields_f14"/><br/>
            </div>
            <div class="d-flex">
               <label class="d-flex fw-bold align-items-center">Language:</label>
               <input type="text" name="Lang" [(ngModel)]="paymentForm.Lang"/><br/>
            </div>
            <div class="d-flex">
               <label class="d-flex fw-bold align-items-center">MerchantID:</label>
               <input type="text" name="MerchantID" [(ngModel)]="paymentForm.MerchantID"/><br/>
            </div>
            <div class="d-flex">
               <label class="d-flex fw-bold align-items-center">NationalID:</label>
               <input type="text" name="NationalID" [(ngModel)]="paymentForm.NationalID"/><br/>
            </div>
            <div class="d-flex">
               <label class="d-flex fw-bold align-items-center">PUN:</label>
               <input type="text" name="PUN" [(ngModel)]="paymentForm.PUN"/><br/>
            </div>
            <div class="d-flex">
               <label class="d-flex fw-bold align-items-center">Payment Description:</label>
               <input type="text" name="PaymentDescription" [(ngModel)]="paymentForm.PaymentDescription"/><br/>
            </div>
            <div class="d-flex">
               <label class="d-flex fw-bold align-items-center">Quantity:</label>
               <input type="text" name="Quantity" [(ngModel)]="paymentForm.Quantity"/><br/>
            </div>
            <div class="d-flex">
               <label class="d-flex fw-bold align-items-center">Transaction Request Date:</label>
               <input type="text" name="TransactionRequestDate" [(ngModel)]="paymentForm.TransactionRequestDate"/><br/>
            </div>
            <div class="d-flex">
               <label class="d-flex fw-bold align-items-center">Secure Hash:</label>
               <input type="textarea" name="SecureHash" [(ngModel)]="paymentForm.SecureHash"/><br/>
            </div>
            <!-- <div class="d-flex">
               <label class="d-flex fw-bold align-items-center">MerchantModuleSessionID:</label>
               <input type="text" name="MerchantModuleSessionID" [(ngModel)]="paymentForm.MerchantModuleSessionID"/><br/>
            </div> -->
            <div><button class="btn btn-sm btn-solid" (click)="onSubmit()">Submit</button></div>
         </form>
</div>