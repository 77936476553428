import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { ToastrModule } from 'ngx-toastr';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { MatSidenavModule } from '@angular/material/sidenav';
import { AppComponent } from './app.component';
import { ShopComponent } from './shop/shop.component';
import { PagesComponent } from './pages/pages.component';
import { ElementsComponent } from './elements/elements.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TermsAndPolicyViewComponent } from './shared/components/terms-and-policy-view/terms-and-policy-view.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ConfirmationDialogueComponent } from './pages/confrimation-dialogue/confrimation-dialogue.component';
import { CancelOrderConfirmationDialogueComponent } from './pages/cancel-order-confrimation-dialogue/cancel-order-confrimation-dialogue.component';

import { Ng2TelInputModule } from 'ng2-tel-input';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AuthInterceptorService } from './shared/services/auth-interceptor.service';
import { AddressConfirmationDialogueComponent } from './pages/address-confirmation-dialogue/address-confirmation-dialogue.component';
import { DatePipe } from '@angular/common';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { SocialLoginModule, SocialAuthServiceConfig, GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login';
import { appInitializer, JwtInterceptor, ErrorInterceptor, fakeBackendProvider } from './_helpers';
import { AccountService } from './_services';
import { PaymentGatewayComponent } from './payment-gateway/payment-gateway.component';
import { NavigateStoreComponent } from './navigate-store/navigate-store.component';
import { DebitPaymentComponent } from './debit-payment/debit-payment.component';


// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
export const HTTP_LOADER_FACTORY: (httpClient: HttpClient) =>
  TranslateHttpLoader = (httpClient: HttpClient): any => new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');

@NgModule({
  declarations: [
    AppComponent,
    ShopComponent,
    PagesComponent,
    ElementsComponent,
    TermsAndPolicyViewComponent,
    ConfirmationDialogueComponent,
    CancelOrderConfirmationDialogueComponent,
    AddressConfirmationDialogueComponent,
    PaymentGatewayComponent,
    NavigateStoreComponent,
    DebitPaymentComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    HttpClientModule,
    NgbModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    MatSidenavModule,
    MatFormFieldModule,
    MatDialogModule,
    Ng2TelInputModule,
    NgxSpinnerModule,
    ToastrModule.forRoot({
      preventDuplicates: true, autoDismiss: true, resetTimeoutOnDuplicate: true, timeOut: 3000,
      positionClass: 'toast-top-center', closeButton: true
    }),
    // TranslateModule.forRoot({
    //     loader: {
    //         provide: TranslateLoader,
    //         useFactory: HttpLoaderFactory,
    //         deps: [HttpClient]
    //     }
    // }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HTTP_LOADER_FACTORY,
        deps: [HttpClient]
      }
    }),
    SharedModule,
    AppRoutingModule,
    SocialLoginModule,
    InfiniteScrollModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true }, DatePipe,
    { provide: MAT_DATE_LOCALE, useValue: 'en-QA' },
    { provide: APP_INITIALIZER, useFactory: appInitializer, multi: true, deps: [AccountService] },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    // provider used to create fake backend
    fakeBackendProvider,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('764143232218461')
          }
        ]} as SocialAuthServiceConfig,
    },
    { provide: 'googleTagManagerId', useValue: 'GTM-TH4QZRHP' },
    GoogleTagManagerService
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
