<div class="card-wraper">
    <div class="img-wrapper">
        <div class="lable-block">
          <div class="d-flex justify-content-between">
          <span class="insta-flag d-flex" *ngIf="product?.is_instant && storeId == 1">
            <img src="assets/images/icon/product-instant.svg" alt="">
          </span>
            <span class="lable3" *ngIf="product && product?.was_price > 0"> {{ 'common.label.onoffer' | translate}}</span>
          </div>
            <span class="lable4">
                <a href="javascript:void(0)" title="Add to Wishlist" (click)="addToWishlist(product)">
                    <div class="stage">
                    <div class="heart" [ngClass]="{ 'is-active': (product?.is_on_wishlist)}"></div>
                </div>
                </a>
            </span>
        </div>
        <div class="front" *ngIf="product && product?.product_image_url">            
            <a [routerLink]="['/shop/product-view/',product?.product_id]">
                <img *ngIf="product.stock_quantity == 0" class="no-stock" src="assets/images/logos/out-of-stock.png" alt="">
                <img [ngClass]="{'stock-none': product.stock_quantity == 0}" [defaultImage]="product?.product_image_url ? product?.product_image_url: 'assets/images/product/placeholder.jpg'"
                    [lazyLoad]="product?.product_image_url ? product?.product_image_url :  'assets/images/product/placeholder.jpg'"
                    class="img-fluid lazy-loading" alt="">
            </a>
        </div>

    </div>    
    <div class="product-detail ">

        <a [routerLink]="['/shop/product-view/', product?.product_id]">
            <h6 class="productName" title="{{ product?.product_name }}">{{ product?.product_name }}</h6>
        </a>
        <div class="QAR_color"> 
          <span><span *ngIf="product?.unit_of_measure == 'KG' || product?.unit_of_measure == 'GR'">Per</span> {{ product?.unit_of_measure }}</span>
        </div>
        <div class="d-flex justify-content-between align-items-end">
            <div>
                <small *ngIf="(product && product?.was_price > 0)">{{ 'common.label.was' | translate}}</small>
                <h4 *ngIf="product && product?.offer_price">
                    <del *ngIf="product && product?.was_price">
                    <span class="money" *ngIf="product && product?.was_price > 0"> {{ product?.was_price }} </span></del>
                    <strong class="discountpricemoney"> {{getValue(product?.offer_price, 'Int')}}.</strong>
                    <strong class="discountfractionmoney">{{getValue(product?.offer_price, 'Fraction')}}</strong>
                    <!-- <span class="QAR_color"> {{product?.product_price.CurrencyCode}}</span> -->
                    <span class="QAR_color"> QAR</span>
                </h4>
            </div>
            <div>
                <button class="btn" [disabled]="product.stock_quantity == 0" (click)="updateQuantity(product,product?.unit_of_measure == 'GR' || product?.unit_of_measure == 'KG' ?  product.minimum_quantity * 1000 : product.minimum_quantity)" *ngIf="!product?.is_on_cart || product.cart_item_quantity === 0">{{ 'common.label.add' | translate}}</button>
                <ng-container matColumnDef="quantity" *ngIf="product?.is_on_cart && product.cart_item_quantity > 0">
   
                    <div class="qty-box">
                      <div class="input-group">
                        <span class="input-group-prepend">
                          <button type="button" class="btn quantity-left-minus" data-type="minus"
                            (click)="increment(product, 'decrement')">
                            <i class="fa fa-minus" aria-hidden="true"></i>
                          </button>
                        </span>
                        <!-- <input type="text" name="quantity" class="form-control input-number quantity_number" disabled
                          [(ngModel)]="product.cart_item_quantity"> -->
                          <div class="quantity_number" [ngClass]="{'unt-kg': product?.unit_of_measure == 'GR' || product?.unit_of_measure == 'KG'}"> {{convertQty(product)}}</div>
                        <span class="input-group-prepend">
                          <button type="button" class="btn quantity-right-plus" data-type="plus" (click)="increment(product, 'increment')">
                            <i class="fa fa-plus" aria-hidden="true"></i>
                          </button>
                        </span>
                      </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>
<app-quick-view #quickView [product]="product" [currency]="currency"></app-quick-view>
<app-cart-modal #cartModal [product]="product" [currency]="currency" *ngIf="cartModal"></app-cart-modal>