import { Component, Input, OnInit } from '@angular/core';
import { HomeService } from '../../services/home.service';

@Component({
  selector: 'app-toggle-menu',
  templateUrl: './toggle-menu.component.html',
  styleUrls: ['./toggle-menu.component.scss']
})
export class ToggleMenuComponent implements OnInit {
  public categories: any;
@Input() alwaysOpen : boolean = false;
togglePosition : string = 'end';
  constructor(public homeService:  HomeService) { 
   let selectedLanguage =  JSON.parse(localStorage.getItem('appSettings')).language;
   if(selectedLanguage == 'ar'){
    this.togglePosition= 'start';
   } else {
    this.togglePosition = 'end';
   }
    this.homeService.getcategories().subscribe((result)=> {
      let response: any = result.data;
      let responseData: any = response.Data; 
      this.categories = responseData;
    })
  }

  ngOnInit(): void {
  }

}
