import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-instant-error-popup',
  templateUrl: './instant-error-popup.component.html',
  styleUrls: ['./instant-error-popup.component.scss']
})
export class InstantErrorPopupComponent implements OnInit {

  constructor( public dialogRef: MatDialogRef<InstantErrorPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
  }
  onYesClick(): void {
    this.dialogRef.close(true);
    //this.toastrService.success(this.translateService.instant('myprofile.mywishlist.label.deletedsuccessfully'), this.translateService.instant('myprofile.creditsandcoupons.label.success!'));
    }  
}
