<form [formGroup]="reactiveForm">

<div class="popup">
    <p>{{ 'myprofile.mywishlist.label.createanewcollection' | translate}}</p>
    <input class="entryform" type="text" [(ngModel)]="formData" placeholder="{{ 'myprofile.mywishlist.label.entercollectionname' | translate}}" formControlName="collection">
   
    <div class="button-container">
        <button class="cancelbtn gradient-btn-o btn" (click)="cancel()">{{ 'myprofile.mywishlist.label.cancel' | translate}}</button>
        <button class="createbtn" (click)="create()" [disabled]="reactiveForm.invalid"> {{ 'myprofile.mywishlist.label.create' | translate}}</button>
        
    </div>
</div>
</form>