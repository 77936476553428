import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-confrimation-dialogue',
  templateUrl: './confrimation-dialogue.component.html',
  styleUrls: ['./confrimation-dialogue.component.scss']
})
export class ConfirmationDialogueComponent {
    // name: string;
    constructor( public dialogRef: MatDialogRef<ConfirmationDialogueComponent>, private translateService: TranslateService,private toastrService: ToastrService,
        @Inject(MAT_DIALOG_DATA) public data: any) { }
  
   onNoClick(): void {
    this.dialogRef.close(false);
   }

   onYesClick(): void {
    this.dialogRef.close(true);
    //this.toastrService.success(this.translateService.instant('myprofile.mywishlist.label.deletedsuccessfully'), this.translateService.instant('myprofile.creditsandcoupons.label.success!'));
   }  

  //  onSubmit(): void {
  //   this.dialogRef.close(this.name);
  //  }

  //  onCancel(): void {
  //   this.dialogRef.close();
  //  }
}
  
