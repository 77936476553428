
                      <section
                      class="product  card-wraper type-product post-1995 status-publish first instock product_cat-apples product_cat-apricots product_cat-bananas product_cat-cabbage product_cat-dates product_cat-eco product_cat-exotic product_cat-figs product_cat-fruits product_cat-pineapple product_cat-plums has-post-thumbnail shipping-taxable purchasable product-type-simple"
                   >
                      <div class="product-wrapper mb-3">
                        <div class="thumbnail-wrapper">
                          <span class="insta-flag" *ngIf="product?.is_instant && storeId == 1">
                            <img src="assets/images/icon/product-instant.svg" alt="">
                          </span>
                          <a [routerLink]="['/shop/product-view/', product?.product_id]">
                            <figure class="no-back-image">
                              <img *ngIf="product.stock_quantity == 0" class="no-stock" style="width: 35px !important;" src="assets/images/logos/out-of-stock.png" alt="">
                              <img [ngClass]="{'stock-none': product.stock_quantity == 0}"
                                [src]="product?.product_image_url ? product?.product_image_url : 'assets/images/product/placeholder.jpg'"
                                class="attachment-shop_catalog wp-post-image ts-lazy-load loaded" alt="product"
                                width="300" height="300"></figure>
                          </a>
                          <div class="product-label">
                          </div>
                        </div>
                        <div class="meta-wrapper">
                          <h3 class="heading-title product-name"><a [routerLink]="['/shop/product-view/',product?.product_id]">{{ product?.product_name }}</a>
                          </h3>
                          <span> <span *ngIf="product?.unit_of_measure == 'KG' || product?.unit_of_measure == 'GR'">Per</span> {{ product?.unit_of_measure }}</span>
                          <img [src]="brandLogo" class="brand-logo" alt="brand-1"
                            decoding="async" loading="lazy">
                        </div>
                        <div class="meta-wrapper meta-wrapper-2 product-detail pr-0">
                        <div class="d-flex flex-column justify-content-between align-items-end">
                            <div>
                              <div class="d-flex discount_text">
                                <small class="text_font" *ngIf="(product && product?.was_price > 0)">{{ 'common.label.was' | translate}}</small>
                                <del *ngIf="product?.was_price"><span class="money"> {{ product?.was_price }}</span></del>
                              </div>
                                <h4 class="d-flex discount_text">
                                  
                    <strong class="discountpricemoney"> {{getValue(product?.offer_price, 'Int')}}.</strong>
                    <strong class="discountfractionmoney">{{getValue(product?.offer_price, 'Fraction')}}</strong><span class="currecny">QAR</span>
                                </h4>
                            </div>
                            <div>
                              <div class="qty-box mt-2" *ngIf="product.cart_item_quantity > 0">
                                <div class="input-group">
                                  <span class="input-group-prepend">
                                    <button type="button" class="btn quantity-left-minus" data-type="minus"
                                      (click)="quatityUpdate(product, 'decrement')">
                                      <i class="fa fa-minus" aria-hidden="true"></i>
                                    </button>
                                  </span>
                                    <div class="quantity_number ml-2 mr-2" [ngClass]="{'unt-kg': product?.unit_of_measure == 'GR' || product?.unit_of_measure == 'KG'}"> {{convertQty(product)}}</div>
                                  <span class="input-group-prepend">
                                    <button type="button" class="btn quantity-right-plus" data-type="plus" (click)="quatityUpdate(product, 'increment')">
                                      <i class="fa fa-plus" aria-hidden="true"></i>
                                    </button>
                                  </span>
                                </div>
                              </div>
                              <button class="btn btn-primary active" [disabled]="product.stock_quantity == 0" (click)="quatityUpdate(product,'increment')" *ngIf="product.cart_item_quantity == 0">Add</button>
                            </div>
                        </div>
                    </div>
                      </div>
                    </section>