
    <mat-sidenav-container class="togglenav-container">
        <mat-sidenav #sidenav id="sideNav" [mode]="'over'" [position]="togglePosition" [opened]="alwaysOpen">

            <app-sub-category (setCategory)="sidenav.toggle()"></app-sub-category>
        </mat-sidenav>
        

        <mat-sidenav-content>
            <div>
            <button mat-button (click)="sidenav.toggle()" class="toggle-button py-0">
                <div class="gradient-menu">
                <img src="../../../../assets/images/icon/category-menu.svg" alt="">
            </div>
            </button>
        </div>
        </mat-sidenav-content>
    </mat-sidenav-container>

