<mat-dialog-content [innerHTML]="data.message | translate"></mat-dialog-content>
<mat-dialog-content [innerHTML]="data.name"></mat-dialog-content>
<mat-dialog-actions align="end">
    <button class="cancelbtn gradient-btn-o btn" (click) = "onNoClick()">{{ 'myprofile.myorders.label.cancel' | translate}}</button>
    <button class="yesbtn" (click) = "onYesClick()">{{ 'myprofile.myorders.label.delete' | translate}}</button>
</mat-dialog-actions>

<!-- <h2>Form Dialog</h2>
<mat-form-field>
    <input matInput placeholder ="enter your name" [(ngModel)]="name">
</mat-form-field>
<button mat-button color="primary" (click)="onSubmit()">Submit</button>
<button mat-button color="primary" (click)="onCancel()">Cancel</button> -->