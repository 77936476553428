import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
  if(window){
    window.console.log = function(){};

  console.warn(`🚨 Console output is disabled on production!`);
  console.log = function (): void { };
  console.debug = function (): void { };
  console.warn = function (): void { };
  console.info = function (): void { };
  console.error = function (): void {};
 }
}

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
});
