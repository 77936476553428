<footer id="colophon" class="footer-container footer-area" *ngIf="!isKeyFrame">
    <div class="container-fluid">
        <div data-elementor-type="wp-post" data-elementor-id="2393" class="elementor elementor-2393"
            data-elementor-settings="[]">
            <div class="elementor-inner">
                <div class="elementor-section-wrap">
                    <section
                        class="elementor-section elementor-top-section elementor-element elementor-element-1312d51 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                        data-id="1312d51" data-element_type="section">
                        <div class="elementor-container elementor-column-gap-no m-0">
                            <div class="elementor-row">
                                <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-7f69d7a9"
                                    data-id="7f69d7a9" data-element_type="column">
                                    <div class="elementor-column-wrap elementor-element-populated">
                                        <div class="elementor-widget-wrap">
                                            <div class="elementor-element elementor-element-5b6af141 elementor-icon-list--layout-inline elementor-align-center elementor-mobile-align-left md-responsive elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                                                data-id="5b6af141" data-element_type="widget"
                                                data-widget_type="icon-list.default">
                                                <div class="elementor-widget-container">
                                                    <ul class="elementor-icon-list-items elementor-inline-items">
                                                        <li class="elementor-icon-list-item elementor-inline-item">
                                                            <span class="elementor-icon-list-icon">
                                                                <img src="assets/images/icon/fresh.svg" alt="">
                                                            </span>
                                                            <span
                                                                class="elementor-icon-list-text">{{'footer.label.premiumdeliveries'
                                                                | translate}}</span>
                                                        </li>
                                                        <!-- <li class="elementor-icon-list-item elementor-inline-item">
                                                            <span class="elementor-icon-list-icon">

                                                                <img src="assets/images/icon/delivery.svg" alt="">
                                                            </span>
                                                            <span
                                                                class="elementor-icon-list-text">{{'footer.label.nocontactdelivery'
                                                                | translate}}</span>
                                                        </li> -->
                                                        <li class="elementor-icon-list-item elementor-inline-item">
                                                            <span class="elementor-icon-list-icon">

                                                                <img src="assets/images/icon/vehicle.svg" alt="">
                                                            </span>
                                                            <span
                                                                class="elementor-icon-list-text">{{'footer.label.freedelivery'
                                                                | translate}}</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="elementor-element elementor-element-5e017c67 elementor-widget-divider--view-line elementor-widget elementor-widget-divider"
                                                data-id="5e017c67" data-element_type="widget" style="padding: 0 30px;"
                                                data-widget_type="divider.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-divider">
                                                        <span class="elementor-divider-separator">
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section
                        class="elementor-section elementor-top-section elementor-element elementor-element-67798227 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                        data-id="67798227" data-element_type="section">
                        <div class="elementor-container elementor-column-gap-no">
                            <div class="elementor-row">
                                <div class="elementor-column elementor-col-70 elementor-top-column elementor-element elementor-element-37985937"
                                    data-id="37985937" data-element_type="column">
                                    <div class="elementor-column-wrap elementor-element-populated">
                                        <div class="elementor-widget-wrap">
                                            <section
                                                class="elementor-section elementor-inner-section elementor-element elementor-element-4cc5fa12 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                                data-id="4cc5fa12" data-element_type="section">
                                                <div class="elementor-container elementor-column-gap-no">
                                                    <div class="elementor-row">
                                                        <div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-553677aa text-center"
                                                            data-id="553677aa" data-element_type="column">
                                                            <div
                                                                class="elementor-column-wrap elementor-element-populated">
                                                                <div
                                                                    class="elementor-widget-wrap justify-content-center">
                                                                    <div class="elementor-element elementor-element-659c595a elementor-widget elementor-widget-text-editor"
                                                                        data-id="659c595a" data-element_type="widget"
                                                                        data-widget_type="text-editor.default">
                                                                        <div class="elementor-widget-container"
                                                                            [routerLink]="['/home/mega-mart']">
                                                                            <div
                                                                                class="elementor-text-editor elementor-clearfix">
                                                                                <a
                                                                                    *ngIf="globalService.getCurrentLanguage() === 'en'">
                                                                                    <img class="alignnone size-full wp-image-2345"
                                                                                        style="margin: 0;"
                                                                                        src="assets/images/logos/Footer-logo-tag.png"
                                                                                        alt="logo" width="190"
                                                                                        height="42"></a>
                                                                                <a
                                                                                    *ngIf="globalService.getCurrentLanguage() === 'ar'">
                                                                                    <img class="alignnone size-full wp-image-2345"
                                                                                        style="margin: 0;"
                                                                                        src="assets/images/logos/footer_arabic_logo_tag.png"
                                                                                        alt="logo" width="190"
                                                                                        height="42"></a>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div class="elementor-element elementor-element-6cb71339 elementor-widget elementor-widget-text-editor"
                                                                        data-id="6cb71339" data-element_type="widget"
                                                                        data-widget_type="text-editor.default">
                                                                        <div class="elementor-widget-container">
                                                                            <div
                                                                                class="elementor-text-editor elementor-clearfix mb-3">
                                                                                <small
                                                                                    style="font-size: 11px;">{{'footer.label.megamartallrightsreserved'
                                                                                    | translate}}</small>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="elementor-element elementor-element-34f8bad2 elementor-position-left elementor-vertical-align-middle elementor-widget__width-initial elementor-widget-tablet__width-initial ts-feature icon-x24 elementor-widget elementor-widget-image-box"
                                                                        data-id="34f8bad2" data-element_type="widget"
                                                                        data-widget_type="image-box.default">
                                                                        <div class="elementor-widget-container mx-3">
                                                                            <div class="elementor-image-box-wrapper">
                                                                                <figure class="elementor-image-box-img">
                                                                                    <img width="24" height="24"
                                                                                        src="assets/images/icon/footer_location.svg"
                                                                                        class="attachment-thumbnail size-thumbnail"
                                                                                        alt="location" loading="lazy">
                                                                                </figure>
                                                                                <div
                                                                                    class="elementor-image-box-content">
                                                                                    <p
                                                                                        (click)="validateUserWithNavigation('shop/store-finder')"
                                                                                        class="elementor-image-box-description text-white"
                                                                                        style="cursor: pointer;">
                                                                                        {{'footer.label.locatethebranchesnearme'
                                                                                        | translate}} </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="elementor-element elementor-element-6cb71339 elementor-widget elementor-widget-text-editor"
                                                                        data-id="6cb71339" data-element_type="widget"
                                                                        data-widget_type="text-editor.default">
                                                                        <div class="elementor-widget-container">
                                                                            <div
                                                                                class="elementor-text-editor elementor-clearfix">
                                                                                <p class="date_color"
                                                                                    style="margin-bottom: 0;">
                                                                                    {{'footer.label.monday'
                                                                                    | translate}} –
                                                                                    {{'footer.label.friday'
                                                                                    | translate}}: 9:00 -
                                                                                    20:00<br>{{'footer.label.saturday'
                                                                                    | translate}}:
                                                                                    11:00 – 15:00</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-7339274e "
                                                            data-id="7339274e" data-element_type="column">
                                                            <div
                                                                class="elementor-column-wrap elementor-element-populated">
                                                                <div
                                                                    class="elementor-widget-wrap justify-content-center">
                                                                    <div class="elementor-element elementor-element-61b7c1ff elementor-widget elementor-widget-heading"
                                                                        data-id="61b7c1ff" data-element_type="widget"
                                                                        data-widget_type="heading.default">
                                                                        <div class="elementor-widget-container">
                                                                            <h6
                                                                                class="elementor-heading-title elementor-size-default">
                                                                                {{'footer.label.needhelp' | translate}}
                                                                            </h6>
                                                                        </div>
                                                                    </div>
                                                                    <div class="elementor-element elementor-element-24bbc2a0 elementor-position-left elementor-vertical-align-middle ts-feature icon-x20 elementor-widget elementor-widget-image-box"
                                                                        data-id="24bbc2a0" data-element_type="widget"
                                                                        data-widget_type="image-box.default">
                                                                        <div class="elementor-widget-container">
                                                                            <div
                                                                                class="elementor-image-box-wrapper align-items-start">
                                                                                <figure class="elementor-image-box-img">
                                                                                    <img width="20" height="20"
                                                                                        src="assets/images/icon/footer_phone.svg"
                                                                                        class="attachment-thumbnail size-thumbnail"
                                                                                        alt="phone" loading="lazy">
                                                                                </figure>
                                                                                <div
                                                                                    class="elementor-image-box-content">
                                                                                    <h6
                                                                                        class="elementor-image-box-title">
                                                                                        +974-30068768</h6>
                                                                                    <h6
                                                                                        class="elementor-image-box-title">
                                                                                        +974-40377444</h6>
                                                                                    <p
                                                                                        class="elementor-image-box-description">
                                                                                        {{'footer.label.247support'
                                                                                        | translate}}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="elementor-element elementor-element-dfef061 elementor-widget-divider--view-line elementor-widget elementor-widget-divider"
                                                                        data-id="dfef061" data-element_type="widget"
                                                                        data-widget_type="divider.default">
                                                                        <div class="elementor-widget-container">
                                                                            <div class="elementor-divider">
                                                                                <span
                                                                                    class="elementor-divider-separator">
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="elementor-element elementor-element-77d06349 elementor-position-left elementor-vertical-align-middle ts-feature icon-x20 elementor-widget elementor-widget-image-box"
                                                                        data-id="77d06349" data-element_type="widget"
                                                                        data-widget_type="image-box.default">
                                                                        <div class="elementor-widget-container">
                                                                            <div class="elementor-image-box-wrapper">
                                                                                <figure class="elementor-image-box-img">
                                                                                    <a
                                                                                        href="mailto:contact@example.com"><img
                                                                                            width="16" height="16"
                                                                                            src="assets/images/icon/footer_email.svg"
                                                                                            class="attachment-thumbnail size-thumbnail"
                                                                                            alt="mail"
                                                                                            loading="lazy"></a>
                                                                                </figure>
                                                                                <div
                                                                                    class="elementor-image-box-content">
                                                                                    <p
                                                                                        class="elementor-image-box-description text-white">
                                                                                        talk2us@megamart.qa</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="elementor-element elementor-element-dfef061 elementor-widget-divider--view-line elementor-widget elementor-widget-divider"
                                                                        data-id="dfef061" data-element_type="widget"
                                                                        data-widget_type="divider.default">
                                                                        <div class="elementor-widget-container">
                                                                            <div class="elementor-divider">
                                                                                <span
                                                                                    class="elementor-divider-separator">
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div class="elementor-element elementor-element-6d88361b elementor-widget__width-auto elementor-widget elementor-widget-wp-widget-ts_social_icons"
                                                                        data-id="6d88361b" data-element_type="widget"
                                                                        data-widget_type="wp-widget-ts_social_icons.default">
                                                                        <div class="elementor-widget-container">
                                                                            <h3
                                                                                class="widget-title heading-title hidden">
                                                                                {{'footer.label.socialicons' |
                                                                                translate}}</h3>
                                                                            <div
                                                                                class="social-icons show-tooltip style-horizontal">
                                                                                <ul class="list-icons">
                                                                                    <li class="facebook"><a href="https://www.facebook.com/megamart.qa"
                                                                                            target="_blank" title=""><i
                                                                                                class="fab fa-facebook-f"></i><span
                                                                                                class="ts-tooltip social-tooltip">
                                                                                                {{'myprofile.contactus.label.facebook'
                                                                                                | translate}}</span></a>
                                                                                    </li>
                                                                                    <li class="instagram"><a href="https://www.instagram.com/megamart.qa/?hl=en"
                                                                                            target="_blank" title=""><i
                                                                                                class="fab fa-instagram"></i><span
                                                                                                class="ts-tooltip social-tooltip">{{'myprofile.contactus.label.instagram'
                                                                                                | translate}}</span></a>
                                                                                    </li>
                                                                                    <li class="tiktok"><a href="https://www.tiktok.com/@megamart.qa"
                                                                                            target="_blank" title=""><i
                                                                                                class="fab fa-tiktok"></i><span
                                                                                                class="ts-tooltip social-tooltip">{{'myprofile.contactus.label.tiktok'
                                                                                                | translate}}</span></a>
                                                                                    </li>
                                                                                    <!-- <li class="pinterest"><a href="#"
                                                                                            target="_blank" title=""><i
                                                                                                class="fab fa-pinterest-p"></i><span
                                                                                                class="ts-tooltip social-tooltip">{{'myprofile.contactus.label.pinterest'
                                                                                                | translate}}</span></a>
                                                                                    </li> 
                                                                                    <li class="linkedin"><a href="#"
                                                                                            target="_blank" title=""><i
                                                                                                class="fab fa-linkedin-in"></i><span
                                                                                                class="ts-tooltip social-tooltip">{{'myprofile.contactus.label.linkedin'
                                                                                                | translate}}</span></a>
                                                                                    </li> -->


                                                                                </ul>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-7339274e  text-center"
                                                            data-id="7339274e" data-element_type="column">
                                                            <div
                                                                class="elementor-column-wrap elementor-element-populated">
                                                                <div class="elementor-widget-wrap">
                                                                    <div class="elementor-element elementor-element-61b7c1ff elementor-widget elementor-widget-heading"
                                                                        data-id="61b7c1ff" data-element_type="widget"
                                                                        data-widget_type="heading.default">
                                                                        <div
                                                                            class="elementor-widget-container contentalign">
                                                                            <h6
                                                                                class="elementor-heading-title elementor-size-default">
                                                                                {{'footer.label.downloadtheapp' |
                                                                                translate}}</h6>
                                                                        </div>
                                                                    </div>
                                                                    <div class="elementor-element elementor-element-6cb71339 elementor-widget elementor-widget-text-editor"
                                                                        data-id="6cb71339" data-element_type="widget"
                                                                        data-widget_type="text-editor.default">
                                                                        <div
                                                                            class="elementor-widget-container contentalign">
                                                                            <div
                                                                                class="elementor-text-editor elementor-clearfix mb-2">
                                                                                <p>{{'footer.label.getthepremiumshoppingexp'
                                                                                    | translate}}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div class="elementor-element elementor-element-34f8bad2 elementor-position-left elementor-vertical-align-middle elementor-widget__width-initial elementor-widget-tablet__width-initial ts-feature icon-x24 elementor-widget elementor-widget-image-box m-auto"
                                                                            data-id="34f8bad2"
                                                                            data-element_type="widget"
                                                                            data-widget_type="image-box.default">
                                                                            <div
                                                                                class="elementor-widget-container box-color">
                                                                                <div
                                                                                    class="elementor-image-box-wrapper">
                                                                                    <figure
                                                                                        class="elementor-image-box-img">
                                                                                        <img width="24" height="24"
                                                                                            src="assets/images/icon/apple_icon.svg"
                                                                                            class="attachment-thumbnail size-thumbnail"
                                                                                            alt="location"
                                                                                            loading="lazy">
                                                                                    </figure>
                                                                                    <div
                                                                                        class="elementor-image-box-content">
                                                                                        <p
                                                                                            class="elementor-image-box-description">
                                                                                            {{'footer.label.downloadonthe'
                                                                                            | translate}}</p>
                                                                                        <p
                                                                                            class="elementor-image-box-description text-white">
                                                                                            {{'footer.label.appstore' |
                                                                                            translate}}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div class="elementor-element elementor-element-34f8bad2 elementor-position-left elementor-vertical-align-middle elementor-widget__width-initial elementor-widget-tablet__width-initial ts-feature icon-x24 elementor-widget elementor-widget-image-box m-auto"
                                                                            data-id="34f8bad2"
                                                                            data-element_type="widget"
                                                                            data-widget_type="image-box.default">
                                                                            <div
                                                                                class="elementor-widget-container box-color">
                                                                                <div
                                                                                    class="elementor-image-box-wrapper">
                                                                                    <figure
                                                                                        class="elementor-image-box-img">
                                                                                        <img width="24" height="24"
                                                                                            src="assets/images/icon/playstore_icon.svg"
                                                                                            class="attachment-thumbnail size-thumbnail"
                                                                                            alt="location"
                                                                                            loading="lazy">
                                                                                    </figure>
                                                                                    <div
                                                                                        class="elementor-image-box-content">
                                                                                        <p
                                                                                            class="elementor-image-box-description">
                                                                                            {{'footer.label.downloadonthe'
                                                                                            | translate}}</p>
                                                                                        <p
                                                                                            class="elementor-image-box-description text-white">
                                                                                            {{'footer.label.playstore' |
                                                                                            translate}}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                    </div>
                                </div>
                                <div class="elementor-column elementor-col-30 elementor-top-column elementor-element elementor-element-3ea905f3"
                                    data-id="3ea905f3" data-element_type="column">
                                    <div class="elementor-column-wrap elementor-element-populated">
                                        <div class="elementor-widget-wrap">
                                            <section
                                                class="elementor-section elementor-inner-section elementor-element elementor-element-30dd5816 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                                data-id="30dd5816" data-element_type="section">
                                                <div class="elementor-container elementor-column-gap-no">
                                                    <div class="elementor-row">
                                                        <div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-6fe45d85"
                                                            data-id="6fe45d85" data-element_type="column">
                                                            <div
                                                                class="elementor-column-wrap elementor-element-populated">
                                                                <div class="elementor-widget-wrap">
                                                                    <div class="elementor-element elementor-element-47fcffa9 elementor-widget elementor-widget-ts-list-of-product-categories"
                                                                        data-id="47fcffa9" data-element_type="widget"
                                                                        data-widget_type="ts-list-of-product-categories.default">
                                                                        <div class="elementor-widget-container">
                                                                            <div
                                                                                class="ts-list-of-product-categories-wrapper columns-2 style-default no-shop-more-button">

                                                                                <h3 class="heading-title">
                                                                                    {{'footer.label.categories' |
                                                                                    translate}} </h3>

                                                                                <div class="row">
                                                                                    <div class="col-12">
                                                                                    <ul >
                                                                                        <li *ngFor="let category of categories?.slice(0, 5)" class="footer-category">
                                                                                            <span (click)="navigateCategoryList(category);"> {{category?.category_name}}</span>
                                                                                        </li>
                                                                                          <a class="footer-category seemore" (click)="explorecategory()">{{ 'common.label.seemore' | translate}}</a>
                                                                                    </ul>
                                                                                </div>
                                                                                
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-745b1ee"
                                                            data-id="745b1ee" data-element_type="column">
                                                            <div
                                                                class="elementor-column-wrap elementor-element-populated">
                                                                <div class="elementor-widget-wrap">
                                                                    <div class="elementor-element elementor-element-502fc8e4 elementor-widget elementor-widget-wp-widget-nav_menu"
                                                                        data-id="502fc8e4" data-element_type="widget"
                                                                        data-widget_type="wp-widget-nav_menu.default">
                                                                        <div class="elementor-widget-container">
                                                                            <h5>{{'footer.label.account' | translate}}
                                                                            </h5>
                                                                            <div class="menu-footer-account-container">
                                                                                <ul id="menu-footer-account"
                                                                                    class="menu">
                                                                                    <li id="menu-item-2093"
                                                                                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-2093 d-block">
                                                                                        <a
                                                                                            (click)="validateUserWithNavigation('/pages/edit-profile');">{{
                                                                                            'sidemenu.label.mypersonaldetails'
                                                                                            | translate}}</a>
                                                                                    </li>
                                                                                    <li id="menu-item-2094"
                                                                                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-2094 d-block">
                                                                                        <a
                                                                                            (click)="validateUserWithNavigation('/pages/myorders');">{{
                                                                                            'sidemenu.label.myorders' |
                                                                                            translate}}</a>
                                                                                    </li>
                                                                                    <!-- <li id="menu-item-2092"
                                                                                        class="menu-item menu-item-type-custom menu-item-object-custom menu-item-2092 d-block">
                                                                                        <a
                                                                                            (click)="validateUserWithNavigation('/pages/myaddress');">{{
                                                                                            'sidemenu.label.myaddresses'
                                                                                            | translate}}</a>
                                                                                    </li> -->
                                                                                    <li id="menu-item-2095"
                                                                                        class="menu-item menu-item-type-custom menu-item-object-custom menu-item-2095 d-block">
                                                                                        <a
                                                                                            (click)="validateUserWithNavigation('/pages/my-wishlist');">{{
                                                                                            'sidemenu.label.mywishlist'
                                                                                            | translate}}</a>
                                                                                    </li>
                                                                                    <!-- <li id="menu-item-2096"
                                                                                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-2096 d-block">
                                                                                        <a
                                                                                            (click)="validateUserWithNavigation('/pages/notification');">{{
                                                                                            'sidemenu.label.notifications'
                                                                                            | translate}}</a>
                                                                                    </li> -->
                                                                                    <!-- <li id="menu-item-2097"
                                                                                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-2096 d-block">
                                                                                        <a
                                                                                            (click)="validateUserWithNavigation('/pages/credits-and-coupons');">{{
                                                                                            'sidemenu.label.credit&coupons'
                                                                                            | translate}}</a>
                                                                                    </li> -->
                                                                                    <!-- <li id="menu-item-2098"
                                                                                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-2096 d-block">
                                                                                        <a (click)="validateUserWithNavigation('/pages/change-password');">{{ 'sidemenu.label.changepassword' | translate}}</a>
                                                                                    </li>
                                                                                    <li id="menu-item-2099"
                                                                                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-2096 d-block">
                                                                                        <a (click)="validateUserWithNavigation('/pages/contact-us');">{{ 'sidemenu.label.contactus' | translate}}</a>
                                                                                    </li>
                                                                                    <li id="menu-item-2100"
                                                                                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-2096 d-block">
                                                                                        <a (click)="validateUserWithNavigation('/pages/terms-and-policies');">{{ 'sidemenu.label.terms&policies' | translate}}</a>
                                                                                    </li> -->
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section
                        class="elementor-section elementor-top-section elementor-element elementor-element-116da846 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                        data-id="116da846" data-element_type="section">
                        <div class="elementor-container elementor-column-gap-no">
                            <div class="elementor-row">
                                <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-74a1383f"
                                    data-id="74a1383f" data-element_type="column">
                                    <div class="elementor-column-wrap elementor-element-populated">
                                        <div class="elementor-widget-wrap">
                                            <div class="elementor-element elementor-element-1ba2cde9 elementor-widget-divider--view-line elementor-widget elementor-widget-divider"
                                                data-id="1ba2cde9" data-element_type="widget"
                                                data-widget_type="divider.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-divider">
                                                        <span class="elementor-divider-separator">
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section
                        class="elementor-section elementor-top-section elementor-element elementor-element-5c1072e0 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                        data-id="5c1072e0" data-element_type="section">
                        <div class="elementor-container elementor-column-gap-no">
                            <div class="elementor-row">
                                <div class="elementor-column elementor-col-10 elementor-top-column elementor-element elementor-element-2d7706c043"
                                    data-id="2d7706c043" data-element_type="column">
                                    <div class="elementor-element elementor-element-57d156e0 elementor-widget__width-auto elementor-widget elementor-widget-ts-language-switcher"
                                        data-id="57d156e0" data-element_type="widget"
                                        data-widget_type="ts-language-switcher.default">
                                        <div class="elementor-widget-container">
                                            <div class="ts-language-switcher dropdown-up">
                                                <div
                                                    class="wpml-ls-statics-shortcode_actions wpml-ls wpml-ls-legacy-dropdown js-wpml-ls-legacy-dropdown">
                                                    <ul>
                                                        <li tabindex="0"
                                                            class="wpml-ls-slot-shortcode_actions wpml-ls-item wpml-ls-item-en wpml-ls-current-language wpml-ls-first-item wpml-ls-item-legacy-dropdown">
                                                            <a href="javascript: void(0)"
                                                                class="js-wpml-ls-item-toggle wpml-ls-item-toggle">
                                                                <span class="wpml-ls-native">{{ (globalService.getCurrentLanguage() === 'en' ?  'ENGLISH' : 'عربي')
                                                                    | translate}}</span>
                                                            </a>
                                                            <ul class="wpml-ls-sub-menu">
                                                                <li
                                                                    class="wpml-ls-slot-shortcode_actions wpml-ls-item wpml-ls-item-fr">
                                                                    <a (click)="customizeLayoutType(layout)" class="wpml-ls-link">
                                                                        <span
                                                                            class="wpml-ls-native">{{ (globalService.getCurrentLanguage() !== 'en' ?  'ENGLISH' : 'عربي')
                                                                            | translate}}</span>
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-2c2215e1"
                                    data-id="2c2215e1" data-element_type="column">
                                    <div class="elementor-column-wrap elementor-element-populated">
                                        <div class="elementor-widget-wrap">
                                            <div class="elementor-element elementor-element-2c0ca8e1 menu-horizontal elementor-widget elementor-widget-wp-widget-nav_menu"
                                                data-id="2c0ca8e1" data-element_type="widget"
                                                data-widget_type="wp-widget-nav_menu.default">
                                                <div class="elementor-widget-container">
                                                    <div class="menu-footer-horizontal-container">

                                                        <ul id="menu-footer-horizontal" class="menu">
                                                            <li id="menu-item-2102"
                                                                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-2102">
                                                                <a [routerLink]="['/shop/aboutmegamart']">{{'footer.label.aboutus'
                                                                    | translate}}</a>
                                                            </li>
                                                            <li id="menu-item-2106"
                                                                class="menu-item menu-item-type-custom menu-item-object-custom menu-item-2106">
                                                                <a (click)="viewPolicy('Terms & Conditions');">
                                                                    {{'footer.label.terms' | translate}} &amp;
                                                                    {{'footer.label.conditions' | translate}} </a>
                                                            </li>
                                                            <!-- <li id="menu-item-2103"
                                                                class="menu-item menu-item-type-custom menu-item-object-custom menu-item-2103">
                                                                <a href="#">{{'footer.label.deliveryinformation' |
                                                                    translate}}</a>
                                                            </li> -->
                                                            <li id="menu-item-2104"
                                                                class="menu-item menu-item-type-custom menu-item-object-custom menu-item-2104">
                                                                <a (click)="viewPolicy('Privacy policy');">{{'footer.label.privacyandpolicy'
                                                                    | translate}}</a>
                                                            </li>
                                                            <li id="menu-item-2104"
                                                                class="menu-item menu-item-type-custom menu-item-object-custom menu-item-2104">
                                                                <a (click)="viewPolicy('Return & cancellation policy');"
                                                                    class="returnandcancel">{{'footer.label.returnandcancellation'
                                                                    | translate}}</a>
                                                            </li>
                                                            <!-- <li id="menu-item-2105"
                                                                class="menu-item menu-item-type-custom menu-item-object-custom menu-item-2105 sales">
                                                                <a href="#">{{'footer.label.sales' | translate}}</a>
                                                            </li> -->
                                                            <!-- <li id="menu-item-2106"
                                                                class="menu-item menu-item-type-custom menu-item-object-custom menu-item-2106">
                                                                <a (click)="viewPolicy('Terms & conditions');">
                                                                    {{'footer.label.terms' | translate}} &amp;
                                                                    {{'footer.label.conditions' | translate}} </a>
                                                            </li> -->
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="elementor-column elementor-col-40 elementor-top-column elementor-element elementor-element-2d7706c0"
                                    data-id="2d7706c0" data-element_type="column">
                                    <div class="elementor-column-wrap elementor-element-populated">
                                        <div class="elementor-widget-wrap">
                                            <div class="elementor-element elementor-element-b333f95 elementor-widget__width-auto elementor-widget elementor-widget-text-editor"
                                                data-id="b333f95" data-element_type="widget"
                                                data-widget_type="text-editor.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-text-editor elementor-clearfix">
                                                        <p style="margin-bottom: 0;" class="payment-details">
                                                            <!-- <img
                                                                src="assets/images/icon/white_icons.svg"
                                                                alt="Icons-white" width="266" height="32"
                                                                class="alignnone size-full wp-image-2853"
                                                                style="margin: 0 !important;"></p> -->
                                                            <img
                                                                src="assets/fonts/visa.svg"
                                                                alt="Icons-white" width="40" height="32"
                                                                class="alignnone size-full wp-image-2853"
                                                                style="margin: 0 !important;">
                                                            <img
                                                                src="assets/fonts/mastercard.svg"
                                                                alt="Icons-white" width="40" height="32"
                                                                class="alignnone size-full wp-image-2853"
                                                                style="margin: 0 !important;">
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </div>
</footer>