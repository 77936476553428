import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ServiceConfigs } from 'src/app/constant/service.config';
import { environment } from 'src/environments/environment';
import { CheckoutList } from '../classes/checkout';
import { ChangePassword, MyPersonalDetails } from '../classes/myprofile';
import { CommonUtil } from '../util/common-util';



@Injectable({
  providedIn: 'root'
})
export class MyProfileService {
  public url: string;

  constructor(private http: HttpClient,
    private config: ServiceConfigs) { }

public getPersonalDetails(): Observable<MyPersonalDetails> {
  this.url=(environment.mock)? this.config.GET_PERSONAL_DETAILS.mockUrl: this.config.GET_PERSONAL_DETAILS.url;
  let queryParams = new HttpParams();
  if (CommonUtil.getStoreIdFromLocalStorageUserProfile('customer_id')) {
    queryParams = queryParams.append("customer_id", CommonUtil.getStoreIdFromLocalStorageUserProfile('customer_id'));
  }

  return this.http.get<MyPersonalDetails>(this.url, { params: queryParams }).pipe(
      catchError((httpErrorResponse: HttpErrorResponse) => of(httpErrorResponse.error))
  );
}

updatePassword(changePassword: ChangePassword): Observable<any> {
  console.log('changePassword    =', changePassword);
  changePassword.customer_id = CommonUtil.getStoreIdFromLocalStorageUserProfile('customer_id');
  const url = (environment.mock) ? this.config.CHANGEPASSWORD.mockUrl : this.config.CHANGEPASSWORD.url;
  return this.http.post<any>(`${url}`,
  changePassword).pipe(
        catchError((httpErrorResponse: HttpErrorResponse) => {
          if (httpErrorResponse.error.status !== 404)  
          return of([]);
      })
      );
}

public updatePersonalDetails(checkoutrequest: MyPersonalDetails): Observable<any> {
  const url=(environment.mock)? this.config.UPDATE_PERSONAL_DETAILS.mockUrl: this.config.UPDATE_PERSONAL_DETAILS.url;
  checkoutrequest.customer_id = CommonUtil.getStoreIdFromLocalStorageUserProfile('customer_id');
  return this.http.post<any>(`${url}`,
  checkoutrequest).pipe(
        catchError((httpErrorResponse: HttpErrorResponse) => {
          if (httpErrorResponse.error.status !== 404)  
          return of([]);
      })
      );
}

contactUs(requestPayload): Observable<any> {
  console.log('changePassword    =', requestPayload);
  const url = this.config.CONTACTUS.url;
  return this.http.post<any>(`${url}`,
  requestPayload)
}
}