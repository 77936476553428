<div class="menu-container">
  <div class="menu-column primary-categories">
    <div class="menu">
      <ul>
        <li  [ngClass]="{ 'active': 'All' == selectedMenu}" 
        (click)="getAllProducts()" (mouseover)="selectAllMenu()">
         <div class="d-flex align-items-center col-gap-10 h-100">
          <img src="assets/images/categories/all_category.svg" alt="">
          <strong>{{ 'menu.label.allproducts' | translate}}</strong>
         </div> </li>
        <li *ngFor="let menu of categories" [ngClass]="{ 'active': menu == selectedMenu}" 
        (click)="navigateCategoryList(menu)" (mouseover)="selectMenu(menu)">
         <div class="d-flex align-items-center col-gap-10 h-100">
          <img [src]="menu.category_image_url" width="31px" alt="">
          <strong>{{ menu.category_name }}</strong>
         </div> </li>
      </ul>
    </div>
  </div>

  <div class="menu-column">
    <div class="submenu" *ngIf="selectedMenu">
      <ul>
        <li *ngFor="let submenu of selectedMenu['sub_categories']"
        (click)="navigateCategoryList(submenu)"  (mouseover)="selectSubmenu(submenu)">
         <div class="d-flex justify-content-between col-gap-10">
          <p class="mb-0"  [ngClass]="{ 'gradient-text': submenu == selectedSubmenu}"  >{{ submenu['category_name'] }}</p>
          <i class="fa" *ngIf="submenu['is_subcategory_available']" [ngClass]="{'fa-angle-right' : (!iSArabic),'fa-angle-left': (iSArabic), 'gradient-icon': submenu == selectedSubmenu}"  ></i>
        </div> 
         </li>
      </ul>
    </div>
  </div>

  <div class="menu-column">
    <div class="nested-submenu" *ngIf="selectedSubmenu">
      <ul>
        <li *ngFor="let nestedSubmenu of selectedSubmenu['sub_categories']"
        (click)="navigateCategoryList(nestedSubmenu)"  (mouseover)="selectNestedSubmenu(nestedSubmenu)">
        
        <div class="d-flex justify-content-between col-gap-10">
          <p class="mb-0"   [ngClass]="{ 'gradient-text': nestedSubmenu == selectedNestedSubmenu}"  >{{ nestedSubmenu.category_name }}</p>
          <i class="fa" [ngClass]="{ 'fa-angle-right' : (!iSArabic),'fa-angle-left': (iSArabic),'gradient-icon': nestedSubmenu == selectedNestedSubmenu}" *ngIf="nestedSubmenu['is_subcategory_available']"  ></i>
        </div> </li>
      </ul>
    </div>
  </div>

  <div class="menu-column">
    <div class="nested-submenu" *ngIf="selectedNestedSubmenu">
      <ul>
        <li *ngFor="let item of selectedNestedSubmenu['sub_categories']" 
        (click)="navigateCategoryList(item)"   (mouseover)="selectItem(item)">
        <div class="d-flex justify-content-between">
          <p class="mb-0"  [ngClass]="{ 'gradient-text': item == selectedItem}"  >{{ item.category_name }}</p>
        </div> 
   </li>
      </ul>
    </div>
  </div>
</div>
