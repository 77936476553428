import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { HomeSlider } from '../../data/slider';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit {
  
  @Input() sliders: any[];
  @Input() class: string;
  @Input() textClass: string;
  @Input() category: string;
  @Input() buttonText: string;
  @Input() buttonClass: string;

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  public HomeSliderConfig: any = HomeSlider;

  navigateOfferScreen(url): void {
    console.log('SLIDER CLICK NAVIGATION =', url);
    //this.router.navigateByUrl(url);
    window.location.href = url;
  }

}
