<div class="main-wrapper">
    <div class="container-fluid pageLayout">
        <div class="row row-gap-15">
            <div class="col-lg-3  col-md-4  pr-0">
                <app-profile-menu></app-profile-menu>
            </div>
            <div class="col-lg-9 col-md-8  menu_align">
                <div class="pageContent" [ngClass]="{'page-usr': !checkIfLogin()}">
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>
    </div>
</div>