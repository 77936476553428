import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit {
@Input() categories: any;
showHeader : boolean = true;
  constructor( private router: Router) { 
    if(this.router.url == '/shop/category-list'){
      this.showHeader = false;
    }
  }

  ngOnInit(): void {
  }
  explorecategory() {
    this.router.navigate(['/shop/category-list']);
  }

  navigateCategoryList(category: any) {
    console.log(category);
    this.router.navigate(["/shop/category-product-list", category?.category_id]);
  }

}
