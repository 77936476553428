import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from '../../services/global.service';
import { GoogleSigninService } from '../../services/google-signin.service';
import { LoginService } from '../../services/login.service';
import { CUSTOMER_PORTAL_CONSTANT } from 'src/app/constant/constant';

@Component({
  selector: 'app-profile-menu',
  templateUrl: './profile-menu.component.html',
  styleUrls: ['./profile-menu.component.scss']
})
export class ProfileMenuComponent implements OnInit {

  constructor(private globalService: GlobalService, private router: Router,private oauth : GoogleSigninService,
    private loginService: LoginService) { }

  ngOnInit(): void {
    
  }

  public checkIfLogin() {
    return JSON.parse(localStorage.getItem(CUSTOMER_PORTAL_CONSTANT.CURRENT_USER))?.customer_id != undefined ? true : false;
  }

  public appLogout(): void {
    this.loginService.logout().subscribe((response: any) => {      
      if (response?.is_success) {
      localStorage.clear();
      this.globalService.previousAppRouterUrl = ''; 
      window.location.assign('/home/mega-mart');    
      }
    }
    );

    // this.oauth.userLogout();
  }

}
