<div class="card-wraper test d-flex flex-column row-gap-10">
    <div class="img-wrapper">
        <div class="front" (click)="navigateBrandProductList(brand?.manufacturer_id)">
            <a>
                <img [src]="brand?.manufacturer_image_url ? brand?.manufacturer_image_url : 'assets/images/product/placeholder.jpg'">
            </a>
        </div>
    </div>

    <div class="text-center">
        <button class="btn explore" (click)="navigateBrandProductList(brand?.manufacturer_id)" >{{ 'common.label.explore' | translate}}</button>
    </div>
</div>