import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CUSTOMER_PORTAL_CONSTANT } from 'src/app/constant/constant';
import { HomeSlider } from '../../../shared/data/slider';

@Component({
  selector: 'app-ads-container',
  templateUrl: './ads-container.component.html',
  styleUrls: ['./ads-container.component.scss']
})
export class AdsContainerComponent implements OnInit {
  
  @Input() adslist: any[];
  

  constructor(private toastrService: ToastrService,
    private router: Router) { }

  ngOnInit(): void {
  }

  public HomeSliderConfig: any = HomeSlider;

  public validateUser(): void {
    if (localStorage.getItem(CUSTOMER_PORTAL_CONSTANT.CURRENT_USER) !== null && localStorage.getItem(CUSTOMER_PORTAL_CONSTANT.CURRENT_USER) ){      
      this.router.navigateByUrl('/pages/credits-and-coupons');
    } else {
        this.toastrService.error('Login to continue','Error');
        this.router.navigateByUrl('/public/login');
    }
  }
}
