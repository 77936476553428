<section
    class="elementor-section elementor-top-section elementor-element elementor-element-1ddb2ce5 elementor-section-stretched elementor-section-boxed elementor-section-height-default elementor-section-height-default">
    <div class="elementor-container elementor-column-gap-no">
        <div class="elementor-row">
            <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-6a930a99"
                data-id="6a930a99" data-element_type="column">
                <div class="elementor-column-wrap elementor-element-populated">
                    <div class="elementor-widget-wrap">
                        <div class="elementor-element elementor-element-98225c1 device-banner-fix-pos elementor-widget elementor-widget-ts-products-in-category-tabs"
                            data-id="98225c1" data-element_type="widget"
                            data-widget_type="ts-products-in-category-tabs.default">
                            <div class="elementor-widget-container">
                                <div class="ts-product-in-category-tab-wrapper ts-shortcode ts-product recent tab-heading--columns style-tabs-vertical-banner style-tabs-vertical has-shop-more-button ts-slider rows-2 has-bg generated-slider"
                                    id="ts-product-in-category-tab-905"
                                    data-atts="{&quot;product_type&quot;:&quot;recent&quot;,&quot;columns&quot;:5,&quot;rows&quot;:&quot;2&quot;,&quot;limit&quot;:10,&quot;product_cats&quot;:&quot;182,183,184,185,186,264&quot;,&quot;include_children&quot;:&quot;0&quot;,&quot;show_image&quot;:&quot;1&quot;,&quot;show_title&quot;:&quot;1&quot;,&quot;show_sku&quot;:&quot;0&quot;,&quot;show_price&quot;:&quot;1&quot;,&quot;show_short_desc&quot;:&quot;0&quot;,&quot;show_rating&quot;:&quot;0&quot;,&quot;show_label&quot;:&quot;1&quot;,&quot;show_categories&quot;:&quot;0&quot;,&quot;show_brands&quot;:&quot;1&quot;,&quot;show_add_to_cart&quot;:&quot;1&quot;,&quot;show_color_swatch&quot;:&quot;0&quot;,&quot;number_color_swatch&quot;:null,&quot;show_shop_more_button&quot;:&quot;1&quot;,&quot;show_shop_more_general_tab&quot;:&quot;1&quot;,&quot;show_general_tab&quot;:&quot;0&quot;,&quot;product_type_general_tab&quot;:&quot;recent&quot;,&quot;is_slider&quot;:&quot;1&quot;,&quot;show_nav&quot;:&quot;0&quot;,&quot;show_dots&quot;:&quot;0&quot;,&quot;auto_play&quot;:&quot;0&quot;}"
                                    data-nav="0" data-dots="0" data-autoplay="0" data-columns="5">
                                    <div class="column-tabs">

                                        <app-category-sub-menu [menuDetails]="categoryDetails" (setSubcategory)="setSelectedCategory($event)"></app-category-sub-menu>
                                    </div>
                                    <div class="banners">

                                        <app-category-banner [categoryBannerDetails]="categoryDetails.categoryBanner"></app-category-banner>
                                    </div>
                                    <div class="column-content px-1">
                                        <app-multi-item-slider [products]="productList | slice:0:sliderMidpoint"
                                            [ProductSliderConfig]="CategorySliderConfig"
                                            [sliderDetails]="productSliderDetails"></app-multi-item-slider>
                                        <div class="pb-2"></div>
                                        <app-multi-item-slider [products]="productList | slice:sliderMidpoint:productList?.length"
                                        [ProductSliderConfig]="CategorySliderConfig"
                                        [sliderDetails]="productSliderDetails"></app-multi-item-slider>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>