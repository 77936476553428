import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef  } from '@angular/material/dialog';

@Component({
  selector: 'app-discount-popup',
  templateUrl: './discount-popup.component.html',
  styleUrls: ['./discount-popup.component.scss']
})
export class DiscountPopupComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<DiscountPopupComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
  }

  onYesClick() {
    this.dialogRef.close();
  }
  
  onDiscount() {
    return this.data.from == 'header' ? true : false;
  }
}
