import { Component, Output, EventEmitter, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmationDialogueComponent } from '../confrimation-dialogue/confrimation-dialogue.component';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class PopUpComponent {
  formData: string = '';
  reactiveForm: FormGroup;
  constructor(public dialogRef: MatDialogRef<PopUpComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private builder: FormBuilder) { }

  create() {
    this.dialogRef.close({ 'result': this.formData });
  }

  cancel() {
    this.dialogRef.close({ 'result': 'Cancel' });
  }
  ngOnInit() {
    this.reactiveForm = this.builder.group({
      collection: [null, Validators.required]
    });
    }
}

