<!-- breadcrumb start -->
<!-- <div class="breadcrumb-section">
  <div class="container">
    <div class="row">
      <div class="col-sm-6">
        <div class="page-title">
          <h2>{{ title }}</h2>
        </div>
      </div>
      <div class="col-sm-6">
        <nav aria-label="breadcrumb" class="theme-breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="'/home/fashion'">Home</a></li>
            <li class="breadcrumb-item active" aria-current="page">{{ breadcrumb }}</li>
          </ol>
        </nav>
      </div>
    </div>
  </div>
</div> -->
<!-- breadcrumb End -->
<div class="breadcrumb-title-wrapper breadcrumb-v1 no-title">
  <div class="breadcrumb-content pl-0 ml-0">
    <div class="breadcrumb-title">
      <div class="breadcrumbs">
        <div class="breadcrumbs-container">
          <ng-container *ngFor="let breadCrumb of breadcrumbDetails">
            <a>{{breadCrumb.name}}</a> 
            <img src="assets/images/icon/breadcrumb_arrow.svg" alt="" class="arrow_breadcrumb">
          </ng-container>
          <b>{{breadcrumb}}</b> 
        </div>
      </div>
    </div>
  </div>
</div>