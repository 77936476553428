<div class="row">
    <div class="col-lg-12 col-12 mx-auto">
  <div class="center-content">
      <!-- <img src="assets/images/substitute_confirmation.svg" alt="Image" class="centered-image"> -->
      <div class="text-container">
        Please select your preferred <b> type of delivery </b> to proceed!
      </div>
      <div class="button-container" >
        <button class="gradient-btn-o btn" (click)="updateDeliveryType('Instant')" >
            <div class="d-flex col-gap-10 align-items-center">
                <img src="../../../assets/images/icon/instant-black.svg" alt="">
                <div>
                    <div class="gradient-text">Instant</div>
                    <span >60 to 120 minutes</span>
                </div>
            </div>
        </button>
        <button class="gradient-btn-o btn" (click)="updateDeliveryType('Schedule')"  >
            <div class="d-flex col-gap-10 align-items-center">
                <img src="../../../assets/images/icon/schedule-black.svg" alt="">
                <div>
                    <div class="gradient-text">Schedule</div>
                    <span>Onyour wish </span>
                </div>
            </div>
        </button>
      </div>
    </div>
  </div>
  </div>