import { Component, Input, OnInit } from '@angular/core';
import { Brands } from '../../classes/home';
import { Router } from '@angular/router';

@Component({
  selector: 'app-brand-slider',
  templateUrl: './brand-slider.component.html',
  styleUrls: ['./brand-slider.component.scss']
})
export class BrandSliderComponent implements OnInit {

  @Input() brands: Brands[];
  @Input() brandSliderConfig: any;
  @Input() sliderDetails: object;
  constructor(private router: Router) { 
  }

  ngOnInit(): void {
  }
}
