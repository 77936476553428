import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../environments/environment';
import { AdsContainer, BrandProductList, Brands, BrandsAds, CategoriesList, MoreDeals, PurchasedItems, Sliders } from '../classes/home';
import { ServiceConfigs } from 'src/app/constant/service.config';
import { CommonUtil } from '../util/common-util';
import { CUSTOMER_PORTAL_CONSTANT } from 'src/app/constant/constant';
import { TranslateService } from '@ngx-translate/core';


@Injectable({
  providedIn: 'root'
})
export class HomeService {
  public Sliders: any;
  public url: string;
  public customerStoreId: BehaviorSubject<any>;

  constructor(private http: HttpClient,
    private config: ServiceConfigs,
    private toastrService: ToastrService,
    private translateService: TranslateService) { 
      this.customerStoreId = new BehaviorSubject(null);
    }


  // Sliders
public getSliders(): Observable<Sliders[]> {
    this.url=(environment.mock)? this.config.GET_SLIDERS.mockUrl: this.config.GET_SLIDERS.url;
    return this.http.get<Sliders>(`${this.url}`).pipe(
        catchError((httpErrorResponse: HttpErrorResponse) => of(httpErrorResponse.error))
    );
  }
  public getBrandSliders(): Observable<Sliders[]> {
    this.url=(environment.mock)? this.config.GET_BRANDSLIDERS.mockUrl: this.config.GET_BRANDSLIDERS.url;
    return this.http.get<Sliders>(`${this.url}`).pipe(
        catchError((httpErrorResponse: HttpErrorResponse) => of(httpErrorResponse.error))
    );
  }
  //ads-container
  public getadscontainer(): Observable<AdsContainer[]> {    
    this.url=(environment.mock)? this.config.GET_ADSCONTAINER.mockUrl: this.config.GET_ADSCONTAINER.url;
    this.url = this.url+'?promotional_period=Day&picture_type=OfferBanner';
    return this.http.get<AdsContainer>(`${this.url}`).pipe(
        catchError((httpErrorResponse: HttpErrorResponse) => of(httpErrorResponse.error))
    );
  }

  //categories
  public getcategories(pageIndex?: number ,pageSize?: number, target_image_size?: number): Observable<any> {
    this.url=(environment.mock)? this.config.GET_CATEGORIES.mockUrl: this.config.GET_CATEGORIES.url;
    let queryParams = new HttpParams();
    if (CommonUtil.getStoreIdFromLocalStorageUserProfile('customer_id')) {
      queryParams = queryParams.append("customer_id", CommonUtil.getStoreIdFromLocalStorageUserProfile('customer_id'));
    }
    if(pageIndex) {
      queryParams = queryParams.append("page_index", pageIndex);
    }
    if(pageSize) {
      queryParams = queryParams.append("page_size", pageSize);
    }
    if(target_image_size) {
      queryParams = queryParams.append("target_image_size", target_image_size);
    }
    queryParams = queryParams.append("sort_by", 'Position');
    queryParams = queryParams.append("store_id", `${CommonUtil.getStoreIdFromLocalStorageUserProfile('store_id')}`);
    //queryParams = queryParams.append("target_image_size", (target_image_size) ? target_image_size : 80);

    return this.http.get<any>(this.url, { params: queryParams }).pipe(
        catchError((httpErrorResponse: HttpErrorResponse) => of(httpErrorResponse.error))
    );
  }
  
  //This week offers
  // DiscountTypeId need to confirm for Dynamic
  // Headers should be dynamic based on token
  public getweekoffer(pageIndex?: number ,pageSize?: number, sortBy?: string): Observable<any> {
    this.url=(environment.mock)? this.config.GET_WEEKOFFER.mockUrl: this.config.GET_WEEKOFFER.url;
    let queryParams = new HttpParams();
    if (CommonUtil.getStoreIdFromLocalStorageUserProfile('customer_id')) {
          queryParams = queryParams.append("customer_id", CommonUtil.getStoreIdFromLocalStorageUserProfile('customer_id'));
        }
        queryParams = queryParams.append("page_index", pageIndex);
        queryParams = queryParams.append("page_size", pageSize);
        queryParams = queryParams.append("sort_by", sortBy );
        queryParams = queryParams.append("promotional_period", 'Day' );
        queryParams = queryParams.append("store_id", `${CommonUtil.getStoreIdFromLocalStorageUserProfile('store_id')}`);
    
        return this.http.get<any>(this.url, { params: queryParams }).pipe(
            catchError((httpErrorResponse: HttpErrorResponse) => of(httpErrorResponse.error))
        );
  }

  //More Deals
  public getmoredeals(): Observable<MoreDeals[]> {
    this.url=(environment.mock)? this.config.GET_MOREDEALS.mockUrl: this.config.GET_MOREDEALS.url;
    return this.http.get<MoreDeals>(`${this.url}`).pipe(
        catchError((httpErrorResponse: HttpErrorResponse) => of(httpErrorResponse.error))
    );
  }

    //Brand List - Brand Advertisement - Middle layer of UI screen
    public getBrandAdvertisement(): Observable<BrandsAds> {
      this.url=(environment.mock)? this.config.GET_BRAND_ADS.mockUrl: this.config.GET_BRAND_ADS.url;
      return this.http.get<BrandsAds>(`${this.url}`).pipe(
          catchError((httpErrorResponse: HttpErrorResponse) => of(httpErrorResponse.error))
      );
    }

  //Brands 
  public getbrands(pageIndex?: number ,pageSize?: number ,sortby?: string): Observable<any> {
    this.url=(environment.mock)? this.config.GET_BRANDS.mockUrl: this.config.GET_BRANDS.url;
    let queryParams = new HttpParams();
    if (CommonUtil.getStoreIdFromLocalStorageUserProfile('customer_id')) {
          queryParams = queryParams.append("customer_id", CommonUtil.getStoreIdFromLocalStorageUserProfile('customer_id'));
        }
        queryParams = queryParams.append("page_index", pageIndex);
        queryParams = queryParams.append("page_size", pageSize);
        queryParams = queryParams.append("sort_by", 'Position');
        queryParams = queryParams.append("store_id", `${CommonUtil.getStoreIdFromLocalStorageUserProfile('store_id')}`);
    
        return this.http.get<any>(this.url, { params: queryParams }).pipe(
            catchError((httpErrorResponse: HttpErrorResponse) => of(httpErrorResponse.error))
        );
  }


 
    //categories list
    public getcategoriesList(): Observable<any> {
      this.url=(!environment.mock)? this.config.GET_CATEGORIESLIST.mockUrl: this.config.GET_CATEGORIESLIST.url;
      return this.http.get<AdsContainer>(`${this.url}`).pipe(
          catchError((httpErrorResponse: HttpErrorResponse) => of(httpErrorResponse.error))
      );
    }

    //Individual categories list
    public getIndividualCategoriesList(i: number, type: string, Id: number): Observable<any> {
      let queryString : string = '?CategoryId='+`${Id}`+'&StoreId='+`${CommonUtil.getStoreIdFromLocalStorageUserProfile('store_id')}`;
      //this.url=(environment.mock)? this.config.GET_INDIVIDUAL_CATEGORIES_LIST.mockUrl + i + '.json': this.config.GET_INDIVIDUAL_CATEGORIES_LIST.url;
      this.url=(environment.mock)? this.config.GET_INDIVIDUAL_CATEGORIES_LIST.mockUrl + i + '.json' : this.config.GET_INDIVIDUAL_CATEGORIES_LIST.url;
      let queryParams = new HttpParams();
      queryParams = queryParams.append("StoreId", `${CommonUtil.getStoreIdFromLocalStorageUserProfile('store_id')}`);
      queryParams = queryParams.append("CategoryId", 'Id');

      return this.http.get<any>(this.url,{ params: queryParams }).pipe(
              catchError((httpErrorResponse: HttpErrorResponse) => of(httpErrorResponse.error))
          );
    }

    //Top categories list
    public getTopCategoriesList(type: string): Observable<any> {
      this.url=(environment.mock && type !== 'realAPI')? this.config.GET_TOP_CATEGORIESLIST.mockUrl: this.config.GET_TOP_CATEGORIESLIST.url;
      
      return this.http.get<any>(`${this.url}`).pipe(
          catchError((httpErrorResponse: HttpErrorResponse) => of(httpErrorResponse.error))
      );
    }

    //Get Top categories Banner 
    public getTopCategoryBanner(categoryIds: any, pageIndex: number, pageSize: number, bannerType: string): Observable<any> {       
      this.url=(environment.mock)? this.config.GET_TOP_CATEGORIES_BANNERS.mockUrl: this.config.GET_TOP_CATEGORIES_BANNERS.url;
      let queryParams = new HttpParams();
      if (CommonUtil.getStoreIdFromLocalStorageUserProfile('customer_id')) {
        queryParams = queryParams.append("customer_id", CommonUtil.getStoreIdFromLocalStorageUserProfile('customer_id'));
      }
      queryParams = queryParams.append("page_index", pageIndex);
      queryParams = queryParams.append("page_size", pageSize);
      //queryParams = queryParams.append("picture_type", 'SinglePortrait');
      queryParams = queryParams.append("picture_type", bannerType);
      queryParams = queryParams.append("entity_type", 'Category');
      queryParams = queryParams.append("store_id", `${CommonUtil.getStoreIdFromLocalStorageUserProfile('store_id')}`);
      //queryParams = queryParams.append("target_image_size", 250);
      +`${categoryIds}`;
      return this.http.get<any>(this.url+categoryIds, { params: queryParams }).pipe(
          catchError((httpErrorResponse: HttpErrorResponse) => of(httpErrorResponse.error))
      );
    }

    //BrandlistBanner 
    public getBrandBanner( pageIndex: number, pageSize: number, manufacturerId: number): Observable<any> {       
      this.url=(environment.mock)? this.config.GET_BRAND_BANNERS.mockUrl: this.config.GET_BRAND_BANNERS.url;
      let queryParams = new HttpParams();
      //  if (CommonUtil.getStoreIdFromLocalStorageUserProfile('customer_id')) {
      //   queryParams = queryParams.append("customer_id", CommonUtil.getStoreIdFromLocalStorageUserProfile('customer_id'));
      // }
      queryParams = queryParams.append("page_index", pageIndex);
      queryParams = queryParams.append("page_size", pageSize);
      queryParams = queryParams.append("entity_ids", manufacturerId);
      queryParams = queryParams.append("entity_type", 'Manufacturer');
      queryParams = queryParams.append("sort_by", 'Position');
      queryParams = queryParams.append("picture_type", 'Banner');
      //queryParams = queryParams.append("store_id", `${CommonUtil.getStoreIdFromLocalStorageUserProfile('store_id')}`);

      return this.http.get<any>(this.url, { params: queryParams }).pipe(
          catchError((httpErrorResponse: HttpErrorResponse) => of(httpErrorResponse.error))
      );
    }

    //recently purchased items
    public getpurchaseditems(pageIndex: number, pageSize: number): Observable<any> {
      this.url=(environment.mock)? this.config.GET_PURCHASEDITEMS.mockUrl: this.config.GET_PURCHASEDITEMS.url;
      let queryParams = new HttpParams();
      if (CommonUtil.getStoreIdFromLocalStorageUserProfile('customer_id')) {
        queryParams = queryParams.append("customer_id", CommonUtil.getStoreIdFromLocalStorageUserProfile('customer_id'));
      }
      queryParams = queryParams.append("page_index", pageIndex);
      queryParams = queryParams.append("page_size", pageSize);
      queryParams = queryParams.append("store_id", `${CommonUtil.getStoreIdFromLocalStorageUserProfile('store_id')}`);

      return this.http.get<any>(this.url, { params: queryParams }).pipe(
          catchError((httpErrorResponse: HttpErrorResponse) => of(httpErrorResponse.error))
      );
    }
    public getWeeklyEssential(pageIndex: number, pageSize: number): Observable<any> {
      this.url=(environment.mock)? this.config.GET_ESSENTIALSITEMS.mockUrl: this.config.GET_ESSENTIALSITEMS.url;
      let queryParams = new HttpParams();
      if (CommonUtil.getStoreIdFromLocalStorageUserProfile('customer_id')) {
        queryParams = queryParams.append("customer_id", CommonUtil.getStoreIdFromLocalStorageUserProfile('customer_id'));
      }
      queryParams = queryParams.append("page_index", pageIndex);
      queryParams = queryParams.append("page_size", pageSize);
      queryParams = queryParams.append("store_id", `${CommonUtil.getStoreIdFromLocalStorageUserProfile('store_id')}`);

      return this.http.get<any>(this.url, { params: queryParams }).pipe(
          catchError((httpErrorResponse: HttpErrorResponse) => of(httpErrorResponse.error))
      );
    }
    //product list 
    public getproductlist(): Observable<PurchasedItems[]> {
      this.url=(!environment.mock)? this.config.GET_PRODUCTLIST.mockUrl: this.config.GET_PRODUCTLIST.url;
      return this.http.get<PurchasedItems>(`${this.url}`).pipe(
          catchError((httpErrorResponse: HttpErrorResponse) => of(httpErrorResponse.error))
      );
    }

    //Brand - product list
    public getBrandBasedProductList(pageIndex: number, pageSize: number, manufacturerId: number, sortBy: string,category_Ids?:number): Observable<any[]> {
      this.url=(environment.mock)? this.config.GET_BRAND_PRODUCT_LIST.mockUrl: this.config.GET_BRAND_PRODUCT_LIST.url;
      let queryParams = new HttpParams();
      if (CommonUtil.getStoreIdFromLocalStorageUserProfile('customer_id')) {
        queryParams = queryParams.append("customer_id", CommonUtil.getStoreIdFromLocalStorageUserProfile('customer_id'));
      }
      queryParams = queryParams.append("manufacturer_ids", manufacturerId);
      queryParams = queryParams.append("page_index", pageIndex);
      queryParams = queryParams.append("page_size", pageSize);
      queryParams = queryParams.append("sort_by", sortBy);
      // if(category_Ids){

      // queryParams = queryParams.append("category_Ids", category_Ids);
      // }
      queryParams = queryParams.append("store_id", `${CommonUtil.getStoreIdFromLocalStorageUserProfile('store_id')}`);

      return this.http.get<any>(this.url, { params: queryParams }).pipe(
          catchError((httpErrorResponse: HttpErrorResponse) => of(httpErrorResponse.error))
      );
    }

    //category list
    public getcategoriesview(): Observable<any> {
      this.url=(environment.mock)? this.config.GET_CATEGORIESVIEW.mockUrl: this.config.GET_CATEGORIESVIEW.url;
      return this.http.get<any>(`${this.url}`).pipe(
          catchError((httpErrorResponse: HttpErrorResponse) => of(httpErrorResponse.error))
      );
    }

    //category-banner 
    public getcategoriesbanner(): Observable<AdsContainer[]> {
      this.url=(environment.mock)? this.config.GET_CATEGORIESLIDER.mockUrl: this.config.GET_CATEGORIESLIDER.url;
      return this.http.get<AdsContainer>(`${this.url}`).pipe(
          catchError((httpErrorResponse: HttpErrorResponse) => of(httpErrorResponse.error))
      );
    }

    //About-megamart banner
    public getaboutusbanner(): Observable<AdsContainer[]> {
      this.url=(environment.mock)? this.config.ABOUTUSBANNER.mockUrl: this.config.ABOUTUSBANNER.url;
      return this.http.get<AdsContainer>(`${this.url}`).pipe(
          catchError((httpErrorResponse: HttpErrorResponse) => of(httpErrorResponse.error))
      );
    }

    //brandproductlist
    public getbrandproductlist(): Observable<AdsContainer[]> {
      this.url=(environment.mock)? this.config.GET_BRANDPRODUCTLIST.mockUrl: this.config.GET_BRANDPRODUCTLIST.url;
      return this.http.get<AdsContainer>(`${this.url}`).pipe(
          catchError((httpErrorResponse: HttpErrorResponse) => of(httpErrorResponse.error))
      );
    }

    // Get top Getegories Details
    public getTopCategories(categoryIds: string, pageIndex: number, pageSize: number, sortBy: string): Observable<any> {
      this.url=(environment.mock) ? this.config.GET_TOP_CATEGORIES_DETAILS.mockUrl: this.config.GET_TOP_CATEGORIES_DETAILS.url;
      let queryParams = new HttpParams();
      if (CommonUtil.getStoreIdFromLocalStorageUserProfile('customer_id')) {
            queryParams = queryParams.append("customer_id", CommonUtil.getStoreIdFromLocalStorageUserProfile('customer_id'));
          }
          queryParams = queryParams.append("page_index", pageIndex);
          queryParams = queryParams.append("page_size", pageSize);
          queryParams = queryParams.append("sort_by", sortBy);
          queryParams = queryParams.append("store_id", `${CommonUtil.getStoreIdFromLocalStorageUserProfile('store_id')}`);
          +`${categoryIds}`;
          return this.http.get<any>(this.url+categoryIds, { params: queryParams }).pipe(
              catchError((httpErrorResponse: HttpErrorResponse) => of(httpErrorResponse.error))
          );
    }
    public getAllProducts( pageIndex: number, pageSize: number, sortBy: string): Observable<any> {
      this.url= this.config.GET_ALL_PRODUCTS.url;
      let queryParams = new HttpParams();
      if (CommonUtil.getStoreIdFromLocalStorageUserProfile('customer_id')) {
            queryParams = queryParams.append("customer_id", CommonUtil.getStoreIdFromLocalStorageUserProfile('customer_id'));
          }
          queryParams = queryParams.append("page_index", pageIndex);
          queryParams = queryParams.append("page_size", pageSize);
          queryParams = queryParams.append("sort_by", sortBy);
          queryParams = queryParams.append("store_id", `${CommonUtil.getStoreIdFromLocalStorageUserProfile('store_id')}`);
       
          return this.http.get<any>(this.url, { params: queryParams }).pipe(
              catchError((httpErrorResponse: HttpErrorResponse) => of(httpErrorResponse.error))
          );
    }
    // Get SortingTypes 
    public getSortingTypes(): Observable<any> {      
      this.url=(environment.mock) ? this.config.GET_SORTING_TYPES.mockUrl: this.config.GET_SORTING_TYPES.url;
      
      return this.http.get<any>(`${this.url}`).pipe(
          catchError((httpErrorResponse: HttpErrorResponse) => of(httpErrorResponse.error))
      );
    }
    public getPrimaryStore(): Observable<any> {      
      this.url=(environment.mock) ? this.config.GET_PRIMARY_STORE.mockUrl: this.config.GET_PRIMARY_STORE.url;
      
      return this.http.get<any>(`${this.url}`).pipe(
          catchError((httpErrorResponse: HttpErrorResponse) => of(httpErrorResponse.error))
      );
    }

    public setCustomerStoreId(storeId: number) {
      this.customerStoreId.next(storeId);
    }
}