import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ServiceConfigs } from 'src/app/constant/service.config';
import { environment } from 'src/environments/environment';
import { AddressList } from '../classes/address';
import { MyOrders } from '../classes/order';
import { CommonUtil } from '../util/common-util';


@Injectable({
  providedIn: 'root'
})
export class AddressService {
  public url: string;

  constructor(private router: Router, private http: HttpClient,
    private config: ServiceConfigs) { }

  
// My Address list 
public getMyAddressList(): Observable<AddressList[]> {
  this.url=(environment.mock)? this.config.GET_MY_ADDRESS_LIST.mockUrl: this.config.GET_MY_ADDRESS_LIST.url;
  let queryParams = new HttpParams();
  if (CommonUtil.getStoreIdFromLocalStorageUserProfile('customer_id')) {
    queryParams = queryParams.append("customer_id", CommonUtil.getStoreIdFromLocalStorageUserProfile('customer_id'));
  }

  return this.http.get<AddressList>(this.url, { params: queryParams }).pipe(
      catchError((httpErrorResponse: HttpErrorResponse) => of(httpErrorResponse.error))
  );
}

// My Address list 
public setDefaultAddress(address: AddressList): Observable<AddressList[]> {
  this.url=(environment.mock)? this.config.SET_DEFAULT_ADDRESS.mockUrl: this.config.SET_DEFAULT_ADDRESS.url;
 

  return this.http.post<AddressList>(this.url, address).pipe(
      catchError((httpErrorResponse: HttpErrorResponse) => of(httpErrorResponse.error))
  );
}

    // Get Area list 
    public getAreaList(): Observable<any> {      
      this.url=(environment.mock) ? this.config.GET_AREA_LIST.mockUrl: this.config.GET_AREA_LIST.url;
      
      return this.http.get<any>(`${this.url}`).pipe(
          catchError((httpErrorResponse: HttpErrorResponse) => of(httpErrorResponse.error))
      );
    }

    // ADD NEW ADDRESS
    public addNewAddress(request): Observable<any> {      
      this.url=(environment.mock) ? this.config.ADD_NEW_ADDRESS.mockUrl: this.config.ADD_NEW_ADDRESS.url;
      
      return this.http.post<any>(`${this.url}`, request).pipe(
          catchError((httpErrorResponse: HttpErrorResponse) => of(httpErrorResponse.error))
      );
    }

    // Update Existing ADDRESS
    public updateExistingAddress(request): Observable<any> {      
      this.url = this.config.UPDATE_EXISTING_ADDRESS.url;
      
      return this.http.post<any>(`${this.url}`, request).pipe(
          catchError((httpErrorResponse: HttpErrorResponse) => of(httpErrorResponse.error))
      );
    }


    // Get Area list 
    public getCityList(): Observable<any> {      
      this.url=(environment.mock) ? this.config.GET_CITY_LIST.mockUrl: this.config.GET_CITY_LIST.url;
      
      return this.http.get<any>(`${this.url}`).pipe(
          catchError((httpErrorResponse: HttpErrorResponse) => of(httpErrorResponse.error))
      );
    }

    //
    public removeAddress(requestData: any): Observable<any> {
      this.url = this.config.REMOVE_ADDRESS.url;
      return this.http.post<any>(`${this.url}`, requestData).pipe(
        catchError((httpErrorResponse: HttpErrorResponse) => of(httpErrorResponse.error))
    );
    }
  
}
