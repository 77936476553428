import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-staticbreadcrumb',
  templateUrl: './staticbreadcrumb.component.html',
  styleUrls: ['./staticbreadcrumb.component.scss']
})
export class StaticbreadcrumbComponent implements OnInit,OnChanges {

  // @Input() title : string;
  @Input() breadcrumb : string;
  @Input() breadcrumbDetails : object[];

  constructor() {
  }

  ngOnInit() : void { 
    
    setTimeout(() => {
      
    }, 1000);
   }
   ngOnChanges(changes: SimpleChanges): void {
   }
}
