<section class="">
    <div class="container-fluid p-0">
        <div class="row">
            <div class="col">
                <!-- <div class="title4 pb-50 d-flex justify-content-between">
                    <div class="text-left">Brand store</div>
                 
                        <span></span>
                  
                    <div>
                        <div [routerLink]="['/shop/brand-list']">
                            Explore all <i class="ti-angle-right"></i>
                        </div>
                    </div>
                </div> -->
                <ng-container *ngIf="sliderDetails['title']">
                    <div class="title4 pb-27 d-flex justify-content-between">
                        <div class="d-flex align-items-baseline col-gap-10">
                        <h2 class="text-left mb-0">{{sliderDetails['title'] | translate}} 
                        </h2>
                    
                    </div>
                        <!-- <div class="line"> -->
                        <!-- </div> -->
                        <div>
                            <a class="explore" [routerLink]="['/shop/brand-list']">
                                {{ 'common.label.exploreall' | translate}} <i class="ti-angle-right"></i>
                            </a>
                        </div>
                    </div>
                </ng-container>
                <owl-carousel-o class="product-5 product-m no-arrow" [options]="brandSliderConfig">
                  <ng-container *ngFor="let brand of brands">
                    <ng-template carouselSlide>
                      <div class="product-box product-wrap">
                          <app-product-brand-box 
                          
                            [brand]="brand">    
                          </app-product-brand-box>
                      </div>
                    </ng-template>
                  </ng-container>
                </owl-carousel-o>
            </div>
        </div>
    </div>
</section>