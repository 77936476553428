import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-debit-payment',
  templateUrl: './debit-payment.component.html',
  styleUrls: ['./debit-payment.component.scss']
})
export class DebitPaymentComponent implements OnInit {

  paymentForm: any = {
    Amount: 0.12,
    CurrencyCode: 634,
    PUN: 'Mega123',
    // MerchantModuleSessionID: 'f859a6e54bd54de4ad3d',
    PaymentDescription: 'SamplePayment',
    NationalID: 2215275104,
    MerchantID: 'DB98327',
    BankID: 'QPAYPG03',
    Lang: 'en',
    Action: 0,
    SecureHash: 'Action=0&Amount=0.12&BankID=QPAYPG03&CurrencyCode=634&ExtraFields_f14=https://megamart.qa/shop/checkout&Lang=en&MerchantID=DB98327&NationalID=22152751049&PUN=Mega123&PaymentDescription=SamplePayment&Quantity=1&TransactionRequestDate=100920241622&SecureHash=d37923f46a7b38e8babce54d8203b3f0fe41e817b1d64299e4ad0355dda4fb76',
    TransactionRequestDate: '10092024154201',
    ExtraFields_f14: 'https://megamart.qa/shop/checkout',
    Quantity: 1
  }
  constructor(private http: HttpClient) { }

  ngOnInit(): void {
  }

  onSubmit() {
    console.log('form value', this.paymentForm);
    this.http.post<any>('https://pguat.qcb.gov.qa/qcb-pg/api/gateway/2.0', this.paymentForm).subscribe({
      next: (response: HttpResponse<any>) => {
        console.log('success response', response)
        if(response.status == 200)
          window.location.href = 'https://pguat.qcb.gov.qa/qcb-pg/api/gateway/2.0';
      },
      error: (response) => {
        console.log('error response', response)
      }
    })
  }
}
