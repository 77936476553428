<div class="container-fluid" [ngClass]="{'p-0': (adslist && adslist.length !== 1) }">
	<div class="row partition3" (click)="validateUser();">
		<div [ngClass]="{'col-md-4': (adslist && adslist.length === 3), 'col-md-6': (adslist && adslist.length === 2), 'col-md-2': (adslist && adslist.length === 6) }"
			*ngFor="let collection of adslist">
				<div class="collection-banner p-left text-left">
					<img [src]="collection.banner_image_url" class="img-fluid" alt="collection-banner">
					<div class="contain-banner " [ngClass]="{'pl-5 py-4': (adslist && adslist.length !== 1) }">
						<div *ngIf = "collection.containername === 'adscontainer' " class="ads-container">
							<h3 class="">{{collection.offer}}</h3>
							<h3 class="">{{collection.title}}</h3>
							<h3 class="">{{collection.subtitle}}</h3>
							
						</div>
						<div *ngIf = "collection.containername === 'dealslist'">
							
						</div>
						<div *ngIf = "collection.containername === 'coupons'">
							<!-- <img [src]="collection.banner_image_url" class="brandimage" alt=""> -->
							<h3 class="brandtitile">{{collection.title}}</h3>
						</div>
						<div *ngIf = "collection.containername === 'brandproductlist'">
							<h3 class="brandtitile">{{collection.title}}</h3>
						</div>
						
					</div>
				</div>
		</div>
	</div>
</div>