<owl-carousel-o [options]="HomeSliderConfig" class="home-slider" [ngClass]="class">
	<ng-container *ngFor="let slider of sliders">
		<ng-template carouselSlide>
			<div class="home" (click)="navigateOfferScreen(slider?.page_url)">
				<img [src]="slider?.banner_image_url" class="img-fluid" alt="slider?.image_alt">
				<div class="slidercontent" *ngIf="buttonText">
					<button class="btn shopnow" (click)="navigateOfferScreen(slider?.page_url)">{{buttonText}}</button>
					<!-- Need to add pageurl , while click on shop now button -->
				</div>
			</div>
		</ng-template>
	</ng-container>
</owl-carousel-o>