import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Product } from 'src/app/shared/classes/product';

@Component({
  selector: 'app-product-brand-box',
  templateUrl: './product-brand-box.component.html',
  styleUrls: ['./product-brand-box.component.scss']
})
export class ProductBrandBoxComponent implements OnInit {

  @Input() brand: any;
  constructor(private router: Router) {
   }

  ngOnInit(): void {
  }
  navigateBrandProductList(id:number){
    console.log(id, 'brandid');
  this.router.navigate(["/shop/brand-product-list", id]);
  
 }
}
