import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-delivery-type-confirmation',
  templateUrl: './delivery-type-confirmation.component.html',
  styleUrls: ['./delivery-type-confirmation.component.scss']
})
export class DeliveryTypeConfirmationComponent implements OnInit {

  constructor( public dialogRef: MatDialogRef<DeliveryTypeConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

onNoClick(): void {
this.dialogRef.close(false);
}

onYesClick(): void {
this.dialogRef.close(true);
//this.toastrService.success(this.translateService.instant('myprofile.mywishlist.label.deletedsuccessfully'), this.translateService.instant('myprofile.creditsandcoupons.label.success!'));
}  
ngOnInit(): void {
  
}

}
