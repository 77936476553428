<div class="banner-wrapper">
    <!-- Here we restrict 2 images only -->
<section *ngFor="let banner of categoryBannerDetails | slice:0:2;">
<a>
    <div class="collection-banner p-left text-left">
        <div class="img-part">
            <img [src]="banner.imagePath" class="img-fluid attachment-full size-full" alt="banner-23" decoding="async"
            loading="lazy"
            sizes="(max-width: 345px) 100vw, 345px" alt="megamart-banner"
            class="responsive-img" 
            [style]="categoryBannerDetails.length === 1 ? 'height:525px' : 'height:260px'">
        </div>
        
    </div>
</a>
</section>
</div>