<!-- <ngx-loading-bar></ngx-loading-bar> -->
<ngx-spinner *ngIf="!isKeyFrame" type="ball-scale-multiple"></ngx-spinner>
<app-header-five></app-header-five>
<div class="container p-0">
        <router-outlet></router-outlet>
</div>
<app-footer-five></app-footer-five>
<app-tap-to-top></app-tap-to-top>
<!-- <app-layout-box></app-layout-box> -->

<!-- <asl-google-signin-button type='icon' size='medium'></asl-google-signin-button> -->