<section
    class="elementor-section elementor-top-section elementor-element elementor-element-2914b52 elementor-section-stretched elementor-section-boxed elementor-section-height-default elementor-section-height-default">
    <div class="elementor-container elementor-column-gap-10">
        <div class="elementor-row">
            <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-11bed701">
                <div class="elementor-column-wrap elementor-element-populated">
                    <div class="elementor-widget-wrap">
                        <div  *ngIf="showHeader" class="elementor-element elementor-element-23d745fc elementor-widget__width-initial elementor-widget elementor-widget-heading">
                            <div class="title4 pb-27 d-flex justify-content-between">
                                <h4 class="text-left mb-0 titlecategory"> {{ 'footer.label.categories' | translate}}</h4>
                                <!-- <div class="line"> -->
                                    <span></span>
                                <!-- </div> -->
                                <div>
                                    <div class="explorebtn" (click)="explorecategory()">
                                        {{ 'common.label.exploreall' | translate}} <i class="ti-angle-right"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="elementor-element elementor-element-151971b9 elementor-widget-tablet__width-initial elementor-widget elementor-widget-ts-product-categories">
                            <div class="elementor-widget-container">
                                <div
                                    class="ts-product-category-wrapper ts-product ts-shortcode woocommerce columns-6 category-style-2 grid show-dots">
                                    <div class="content-wrapper ">
                                        <div class="products">
                                            <section class="product-category product" *ngFor="let category of categories;">
                                                <div class="product-wrapper" (click)="navigateCategoryList(category)">
                                                    <a href="javascript:void(0)" class="imageallign">
                                                        <img decoding="async" loading="lazy" 
                                                            [src]="category?.category_image_url"
                                                            [alt]="category?.category_name" width="600" height="600"
                                                            [srcset]="category?.category_image_url"
                                                            sizes="(max-width: 110px) 100vw, 110px"> </a>
                                                    <div class="meta-wrapper" class="catgname">
                                                        <div class="category-name">
                                                            <h3 class="heading-title">
                                                                <a href="javascript:void(0)" class="categorynames">
                                                                
                                                                    {{category?.category_name}}
                                                                </a>
                                                            </h3>
                                                        </div>
                                                    </div>
                                                    <a href="javascript:void(0)" class="term-link"></a>
                                                </div>
                                            </section>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>