import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CUSTOMER_PORTAL_CONSTANT } from 'src/app/constant/constant';
import { HomeService } from '../../services/home.service';

@Component({
  selector: 'app-sub-category',
  templateUrl: './sub-category.component.html',
  styleUrls: ['./sub-category.component.scss']
})
export class SubCategoryComponent implements OnInit {
  public categories: any = [];
  @Input() selectedCategoty: any;
  @Output() setCategory  : EventEmitter<any> = new EventEmitter<any>();
  constructor(public router: Router ,public homeService:  HomeService) { 
    this.homeService.getcategories().subscribe((result) => {
      let response: any = result?.data;
      let responseData: any = response;
      if(responseData) {
        console.log('responseData length =', responseData.length)
        responseData.forEach(element => {
          // if (element?.display_order <= 100) {
            this.categories.push(element);
          // }
        });
      }
      //this.categories = responseData;
      console.log('aaaaaaaaaaaaaaaaaa =', this.categories.length);
    })
  }

  ngOnInit(): void {
  }
  oncategorySelected(selectedCategory){
    console.log(selectedCategory)
    this.setCategory.emit(selectedCategory)
  }

  navigateCategoryList(category: any) {
    this.router.navigate(["/shop/category-product-list", category?.category_id]);
  }

}

