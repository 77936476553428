<div class="sidebar" [ngClass]="{ 'no-login': !checkIfLogin() }">
      <div class="link" *ngIf="checkIfLogin()">
        <div class="content" [routerLink]="['/pages/edit-profile']" [routerLinkActive]="'active'">
          <div class="icon"><img src="../../../../assets/images/icon/profile-gradient.svg" alt=""></div>
          <div class="title">{{ 'sidemenu.label.mypersonaldetails' | translate}} </div>
        </div>
      </div>
      <div class="link" *ngIf="checkIfLogin()">
        <div class="content" [routerLink]="['/pages/myorders']" [routerLinkActive]="'active'">
          <div class="icon"><img src="../../../../assets/images/icon/orders-gradient.svg" alt=""></div>
          <div class="title">{{ 'sidemenu.label.myorders' | translate}} </div>
        </div>
      </div>
      <div class="link" *ngIf="checkIfLogin()">
        <div class="content" [routerLink]="['/pages/myaddress']" [routerLinkActive]="'active'">
          <div class="icon"><img src="../../../../assets/images/icon/address-gradient.svg" alt=""></div>
          <div class="title">{{ 'sidemenu.label.myaddresses' | translate}}</div>
        </div>
      </div>
      <div class="link" *ngIf="checkIfLogin()">
        <div class="content" [routerLink]="['/pages/my-wishlist']" [routerLinkActive]="'active'">
          <div class="icon"><img src="../../../../assets/images/icon/wishlist-gradient.svg" alt=""></div>
          <div class="title">{{ 'sidemenu.label.mywishlist' | translate}}</div>
        </div>
      </div>
      <div class="link" *ngIf="checkIfLogin()">
        <div class="content" [routerLink]="['/pages/notification']" [routerLinkActive]="'active'">
          <div class="icon"><img src="../../../../assets/images/icon/notification-gradient.svg" alt=""></div>
          <div class="title">{{ 'sidemenu.label.notifications' | translate}}</div>
        </div>
      </div>
      <div class="link">
        <div class="content" [routerLink]="['/pages/credits-and-coupons']" [routerLinkActive]="'active'">
          <div class="icon"><img src="../../../../assets/images/icon/credits-gradient.svg" alt=""></div>
          <div class="title">{{ 'sidemenu.label.credit&coupons' | translate}}</div>
        </div>
      </div>
      <div class="link" *ngIf="checkIfLogin()">
        <div class="content" [routerLink]="['/pages/change-password']" [routerLinkActive]="'active'">
          <div class="icon"><img src="../../../../assets/images/icon/change-password-gradient.svg" alt=""></div>
          <div class="title">{{ 'sidemenu.label.changepassword' | translate}}</div>
        </div>
      </div>
      <div class="link">
        <div class="content" [routerLink]="['/pages/contact-us']" [routerLinkActive]="'active'">
          <div class="icon"><img src="../../../../assets/images/icon/contactUs-gradient.svg" alt=""></div>
          <div class="title">{{ 'sidemenu.label.contactus' | translate}}</div>
        </div>
      </div>
      <div class="link">
        <div class="content" [routerLink]="['/pages/terms-and-policies']" [routerLinkActive]="'active'">
          <div class="icon"><img src="../../../../assets/images/icon/T&c-gradient.svg" alt=""></div>
          <div class="title">{{ 'sidemenu.label.terms&policies' | translate}}</div>
        </div>
      </div>
      <div class="link" *ngIf="checkIfLogin()">
        <div class="content" (click)="appLogout();">
          <div class="icon"><img src="../../../../assets/images/icon/logout-gradient.svg" alt=""></div>
          <div class="title">{{ 'sidemenu.label.logout' | translate}}</div>
        </div>
      </div>
    </div>